import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ConfirmPopModule } from '@zerops/zui/confirm-pop';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { PopStateModule } from '@zerops/zui/pop-state';
import { TaskMorePopFeature } from './task-more-pop.feature';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';

@NgModule({
  declarations: [ TaskMorePopFeature ],
  imports: [
    CommonModule,
    SatPopoverModule,
    MatCardModule,
    MatListModule,
    RouterModule,
    ActionListItemModule,
    PopStateModule,
    ConfirmPopModule,
    DynamicPopAnchorModule
  ],
  exports: [ TaskMorePopFeature ]
})
export class TaskMorePopModule {

}
