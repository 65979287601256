import { createSelector, createFeatureSelector, } from '@ngrx/store';
import { SlugStateState } from './slug-state.model';
import { FEATURE_NAME } from './slug-state.constant';

export const selectSlugStateSlice = createFeatureSelector<SlugStateState>(FEATURE_NAME);

export const selectKeyIdBySlug = (key: string, slug: string) => createSelector(
  selectSlugStateSlice,
  (slice) => slice.slugMap && slice.slugMap[key]
    ? slice.slugMap[key][slug]
    : undefined
);
