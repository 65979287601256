import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { EfforEntryBaseEffect } from './effort-entry-base.effect';

@NgModule({
  imports: [ EffectsModule.forFeature([ EfforEntryBaseEffect ]) ]
})
export class EffortEntryBaseModule {

}
