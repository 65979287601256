import { Component } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { formValueOnValid } from '@zerops/zef/forms';
import { DriverIds } from '@zerops/zemag/core/drivers-base';
import { StrapiUploadService } from '@zerops/zemag/core/strapi';
import { TaskCategoryEntity } from '@zerops/zemag/core/task-category-base';
import { TaskEffortEntity } from '@zerops/zemag/core/task-effort-base';
import { TaskInternalPriorityEntity } from '@zerops/zemag/core/task-internal-priority-base';
import { TaskStatusEntity } from '@zerops/zemag/core/task-status-base';
import { TasksEntity } from '@zerops/zemag/core/tasks-base';
import { UsersEntity } from '@zerops/zemag/core/users-base';
import { unbox } from 'ngrx-forms';
import { Subject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { TaskAddFormForm } from './modules';
import { select, Store } from '@ngrx/store';
import { zefSelectAuthData } from '@zerops/zef/auth';

@Component({
  selector: 'zg-task-add',
  templateUrl: './task-add.feature.html',
  styleUrls: [ './task-add.feature.scss' ]
})
export class TaskAddFeature extends ZefReactiveComponent {

  // # Event Streams
  onAdd$ = new Subject<void>();

  // # Forms
  formState$ = this._taskAddForm.state$;

  // # Data
  // -- sync
  addKey = this._taskEntity.addOne.type;

  // -- async
  taskInternalPriorities$ = this._taskInternalPriorityEntity.list$();
  users$ = this._userEntity.list$().pipe(map((d) => d?.filter((itm) => !itm.archived)));
  taskStatuses$ = this._taskStatusEntity.list$();
  taskCategories$ = this._taskCategoryEntity.list$();
  taskEfforts$ = this._taskEffortEntity.list$();

  // # State resolver
  state = this.$connect({
    formState: this.formState$,
    taskInternalPriorities: this.taskInternalPriorities$,
    taskStatuses: this.taskStatuses$,
    taskCategories: this.taskCategories$,
    taskEfforts: this.taskEfforts$,
    users: this.users$
  });

  private _editor: any;

  // # Action Streams
  private _addAction$ = this.onAdd$.pipe(
    formValueOnValid(this._taskAddForm),
    withLatestFrom(this._store.pipe(select(zefSelectAuthData))),
    map(([ data, authData ]) => this._taskEntity.addOne({
      data: {
        ...data,
        assignees: data.assignees ? unbox(data.assignees).map((d) => ({
          user: d,
          done: false
        })) : [],
        author: authData.userId,
        drivers: [
          {
            driver: DriverIds.Ux,
            value: data.drivers.value.ux
          },
          {
            driver: DriverIds.Marketing,
            value: data.drivers.value.marketing
          },
          {
            driver: DriverIds.Sustainability,
            value: data.drivers.value.sustainability
          }
        ]
      }
    }, {
      handleGlobally: true,
      strapiPopulate: {
        populate: 'drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort'
      }
    })
  ));

  constructor(
    private _store: Store<any>,
    private _taskAddForm: TaskAddFormForm,
    private _taskEntity: TasksEntity,
    private _taskEffortEntity: TaskEffortEntity,
    private _taskStatusEntity: TaskStatusEntity,
    private _taskInternalPriorityEntity: TaskInternalPriorityEntity,
    private _taskCategoryEntity: TaskCategoryEntity,
    private _userEntity: UsersEntity,
    private _strapiUploadService: StrapiUploadService
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._addAction$
    ]);

  }

  editorInit(editor: any) {
    if (!editor) { return; }

    this._editor = editor;
  }

  onPaste(e: any) {
    if (this._editor?.hasTextFocus()) {
      const items = e.clipboardData.items;

      const selection = this._editor.getSelection();

      for (const item of items) {
        const matches = item.type.match(/^image\/(png|jpg|jpeg|gif)$/i);
        if (matches) {
          const blob = item.getAsFile();
          this._strapiUploadService.upload(blob).subscribe((res: any) => {
            this._editor.executeEdits('', [
              {
                range: selection,
                /**
                range: new Range(
                  selection.endLineNumber,
                  selection.endColumn,
                  selection.endLineNumber,
                  selection.endColumn
                  ),
                */
                text: `![](${res[0].url})`
              }
            ])
            const { endLineNumber, endColumn } = this._editor.getSelection();
            this._editor.setPosition({ lineNumber: endLineNumber, column: endColumn });
          })
        }
      }

    }
  }

}
