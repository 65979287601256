import { inject } from '@angular/core';
import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn
} from '@angular/common/http';
import { API_ENDPOINT } from '@zerops/zemag/app';
import { environment } from '@zerops/zemag/env';

export const apiBaseInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) =>  {
  const _apiEndpoint = inject(API_ENDPOINT, {optional: true});
  const endpoint = _apiEndpoint ? _apiEndpoint : environment.apiUrl;
  if (request.url.startsWith('/api') && !request.url.includes(endpoint)) {
    request = request.clone({
      url: endpoint + request.url
    });
  }
  return next(request);
}
