import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TasksBaseEffect } from './tasks-base.effect';

@NgModule({
  imports: [ EffectsModule.forFeature([ TasksBaseEffect ]) ]
})
export class TasksBaseModule {

}
