import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { SatPopover } from '@zerops/zef/popover';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FEATURE_NAME } from './effort-entries-pop.constant';
import { EffortEntriesPopService } from './effort-entries-pop.service';

@Component({
  selector: 'zg-effort-entries-pop',
  templateUrl: './effort-entries-pop.feature.html',
  styleUrls: [ './effort-entries-pop.feature.scss' ]
})
export class EffortEntriesPopFeature extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams

  // # Data
  // -- sync
  featureName = FEATURE_NAME;

  // -- angular
  @ViewChild(SatPopover, { static: true })
  popRef: SatPopover;

  // -- async
  popState$ = this._store.pipe(
    select(selectZefDialogState(this.featureName))
  );
  taskId$ = this.popState$.pipe(
    map((d) => d.meta?.taskId as number),
    distinctUntilChanged()
  );
  userId$ = this.popState$.pipe(
    map((d) => d.meta?.userId as number),
    distinctUntilChanged()
  );

  // # State resolver
  state = this.$connect({
    taskId: this.taskId$,
    userId: this.userId$
  });

  // # Action Streams


  constructor(
    private _store: Store<any>,
    private _effortEntriesPopService: EffortEntriesPopService
  ) {
    super();
  }

  ngAfterViewInit() {
    this._effortEntriesPopService.saveRef(this.popRef);
  }

}
