<!-- Effort entry add edit pop -->
<sat-popover
  [autoFocus]="false"
  [restoreFocus]="false"
  [verticalAlign]="'below'"
  [horizontalAlign]="'end'"
  hasBackdrop
  [zuiPopState]="featureName"
  (backdropClicked)="popRef.close()">
  <mat-card class="c-soft-elevation">
    <zef-form
      [state]="state.formState"
      (submit)="state.mode === 'add' ? onAdd$.next() : onUpdate$.next()">

      <zef-form-field>

        <mat-form-field class="__datepicker" color="accent">

          <mat-label>Created</mat-label>

          <input
            disabled
            matInput
            [matDatepicker]="picker"
            [max]="maxDate"
            [ngrxEnableFocusTracking]="true"
            [ngrxFormControlState]="state.formState?.controls.createdAt"
            [ngrxValueConverter]="dateValueConverter"
          />

          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

          <mat-datepicker #picker></mat-datepicker>

        </mat-form-field>

      </zef-form-field>

      <zef-form-field>
        <mat-form-field color="accent">

          <mat-label>Effort</mat-label>

          <input
            matInput
            type="number"
            name="effort"
            [placeholder]="'Enter hours..'"
            [ngrxEnableFocusTracking]="true"
            [ngrxFormControlState]="state.formState?.controls.effort"
          />

        </mat-form-field>

      </zef-form-field>

      <zef-progress-error
        full
        [key]="[ addKey, updateKey ]">
        <button
          class="__button  c-button--full"
          mat-flat-button
          type="submit"
          color="accent">
          Save record
        </button>
      </zef-progress-error>

    </zef-form>
  </mat-card>
</sat-popover>
