<div class="__wrap">

  <button
    *ngIf="state.taskId && (state.userId === state.authorizedUserId)"
    type="button"
    mat-button
    class="__add-button  c-button--smaller"
    #addRecordRef="dynamicPopAnchor"
    (click)="addRecordRef.open()"
    [zuiDynamicPopAnchor]="effortEntryAddEditPopRef"
    [zefNgrxDialogOpen]="effortEntryAddEditPopKey"
    [zefNgrxDialogOpenMeta]="{ mode: 'add', taskId: state.taskId }">
    <mat-icon>add</mat-icon>
    Add record
  </button>

  <zui-table
    class="__table"
    [tableData]="state.taskId ? state.taskEffortEntries : state.effortEntries"
    [tableColumns]="columns"
    [isSearchable]="false">

    <span *zuiTableCellTemplate="'attributes.task_id'; let item">
      <a class="__link" [routerLink]="[ '/task', item.attributes.task_id ]">#z{{ item.attributes.task_id }}</a>
    </span>

    <span *zuiTableCellTemplate="'attributes.created'; let item">
      {{ item.attributes.created | date: 'mediumDate' }}
    </span>

    <span *zuiTableCellTemplate="'attributes.hour_effort'; let item">
      {{ item.attributes.hour_effort }}h
    </span>

    <span *zuiTableCellTemplate="'actions'; let item">
      <div
        *ngIf="state.authorizedUserId === state.userId"
        class="__actions">

        <button
          type="button"
          mat-icon-button
          class="__button"
          #editRecordRef="dynamicPopAnchor"
          (click)="editRecordRef.open()"
          [zuiDynamicPopAnchor]="effortEntryAddEditPopRef"
          [zefNgrxDialogOpen]="effortEntryAddEditPopKey"
          [zefNgrxDialogOpenMeta]="{ mode: 'edit', taskId: state.taskId ? state.taskId : item.attributes.task_id, effortEntryId: item.id }">
          <mat-icon class="__button-icon">edit</mat-icon>
        </button>

        <zui-progress-spinner
          hideContent
          [isLoading]="state.deleteLoadingsMap[item.id]">
          <button
            type="button"
            mat-icon-button
            color="warn"
            (click)="onDelete$.next(item.id)"
            class="__button">
            <mat-icon class="__button-icon">delete</mat-icon>
          </button>
        </zui-progress-spinner>

      </div>
    </span>

  </zui-table>

</div>
