import {
  Component,
  Input,
  ViewChild,
  ContentChildren,
  Directive,
  TemplateRef,
  QueryList,
  ContentChild
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort, Sort } from '@angular/material/sort';
import orderBy from 'lodash-es/orderBy';

export interface TableColumn {
  name: string;
  key: string;
  type?: any;
  position?: 'right' | 'left';
  isSortable?: boolean;
}

// link directive
@Directive({
  selector: '[zuiTableLinkTemplate]'
})
export class TableLinkTemplateDirective { }

// cell directive
@Directive({
  selector: '[zuiTableCellTemplate]'
})
export class TableCellTemplateDirective {
  @Input()
  zuiTableCellTemplate: string;

  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'zui-table',
  templateUrl: './table.container.html',
  styleUrls: [ './table.container.scss' ]
})
export class TableContainer {

  // # Event Streams

  // # Data
  // -- sync
  tableDataSource = new MatTableDataSource([]);
  displayedColumns: string[];
  cellTemplatesMap: Record<string, TemplateRef<any>> = {};
  private _cellTemplates: QueryList<TableCellTemplateDirective>;
  private _cachedTableData = [];
  private _linkTemplate: TemplateRef<any>;

  // -- async

  // -- angular
  @ViewChild(MatPaginator, { static: false })
  matPaginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  matSort: MatSort;

  @ContentChild(TableLinkTemplateDirective, { read: TemplateRef })
  set linkTemplate(v) {
    this._linkTemplate = v;
  }
  get linkTemplate() { return this._linkTemplate; }

  @ContentChildren(TableCellTemplateDirective)
  set cellTemplates(v) {
    this._cellTemplates = v;

    if (v) {
      v.forEach((cell) => {
        this.cellTemplatesMap[cell.zuiTableCellTemplate] = cell.templateRef;
      });
    } else {
      this.cellTemplatesMap = {};
    }

  }
  get cellTemplates() {
    return this._cellTemplates;
  }

  @Input()
  isPageable = true;

  @Input()
  isSortable = true;

  @Input()
  isSearchable = true;

  @Input()
  filterParam: string;

  @Input()
  filterPlaceholder: string;

  @Input()
  tableColumns: TableColumn[];

  @Input()
  paginationSizes: number[] = [ 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500 ];

  @Input()
  defaultPageSize = this.paginationSizes[this.paginationSizes.length - 1];

  @Input()
  trackByColumn = 'id';

  @Input()
  set tableData(data: any[]) {
    this._setTableDataSource(data);
  }

  // # Action Streams

  searchTable(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = value.trim().toLowerCase();
  }

  sortTable(sortParameters: Sort) {
    const columnType = this.tableColumns.find(column => column.name === sortParameters.active).type;
    sortParameters.active = this.tableColumns.find(column => column.name === sortParameters.active).key;

    if (sortParameters.active && sortParameters.direction) {
      switch (columnType) {

        // TODO: fix universal date sorting
        case 'date':
          this.tableDataSource.data = orderBy(this.tableDataSource.data, sortParameters.active, sortParameters.direction);
          break;

        default:
          this.tableDataSource.data = orderBy(this.tableDataSource.data, sortParameters.active, sortParameters.direction);
      }

    } else {
      this.tableDataSource.data = this._cachedTableData;
    }
  }

  trackBy(index: number, item: any) {
    return item['id'];
  }

  private _setTableDataSource(data: any) {
    this._cachedTableData = data;
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;

    setTimeout(() => {
      const columnNames = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.name);

      if (this.linkTemplate) {
        columnNames.unshift('_link');
      }

      this.displayedColumns = columnNames;
      this.tableDataSource.paginator = this.matPaginator;
      this.tableDataSource.filterPredicate = (data, filter: string)  => {
        const dataStr = JSON.stringify(this.filterParam ? data[this.filterParam] : data).toLowerCase();
        return dataStr.indexOf(filter) != -1;
      };

    }, 0);
  }
}
