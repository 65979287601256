import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zefLogout } from '@zerops/zef/auth';
import { zefResetState } from '@zerops/zef/core';
import { zefDialogClose, zefDialogOpen } from '@zerops/zef/dialog';
import { successOf } from '@zerops/zef/entities';
import { zefGo } from '@zerops/zef/ngrx-router';
import { filter, map, switchMap } from 'rxjs/operators';
import { TasksEntity } from '../core/tasks-base';
import { TaskAddFormForm } from '../features/task-add';
import { TASK_ADD_DIALOG_KEY, TASK_DETAIL_DIALOG_KEY } from './app.constant';

@Injectable()
export class AppEffect {
  private _onLogout$ = this._actions$.pipe(ofType(zefLogout));

  private _onTaskAddDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === TASK_ADD_DIALOG_KEY),
    switchMap(() => [
      this._taskAddForm.reset(),
      this._taskAddForm.setDefaultValues()
    ])
  ));

  private _closeDialogOnAdd$ = createEffect(() => this._actions$.pipe(
    successOf(this._taskEntity.addOne),
    map(() => zefDialogClose({ key: TASK_ADD_DIALOG_KEY }))
  ));

  private _closeDialogOnUpdate$ = createEffect(() => this._actions$.pipe(
    successOf(this._taskEntity.updateOne),
    filter((action) => !!action.originalAction.meta?.isDialogEdit),
    map((action: any) => zefDialogOpen({
      key: TASK_DETAIL_DIALOG_KEY,
      meta: { id: action.originalAction.data.id, edit: false }
    }))
  ));

  private _onLogoutRedirect$ = createEffect(() => this._onLogout$.pipe(
    map(() => zefGo([ '/login' ]))
  ));

  private _onLogoutResetState$ = createEffect(() => this._onLogout$.pipe(
    map(() => zefResetState())
  ));

  constructor(
    private _actions$: Actions,
    private _taskAddForm: TaskAddFormForm,
    private _taskEntity: TasksEntity
  ) {}
}
