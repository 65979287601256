import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { successOf } from '@zerops/zef/entities';
import { TasksEntity } from '@zerops/zemag/core/tasks-base';

@Injectable()
export class TaskDetailEffect {

  private _onUpdateSuccess$ = this._actions$.pipe(
    successOf(this._tasksEntity.updateOne),
  );

  private _onDeleteSuccess$ = this._actions$.pipe(
    successOf(this._tasksEntity.deleteOne),
  );

  constructor(
    private _tasksEntity: TasksEntity,
    private _actions$: Actions
  ) { }

}
