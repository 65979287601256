import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zemag/app';
import { validate, FormState, box, Boxed } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { FEATURE_NAME } from '../../task-add.constant';

export interface TaskAddFormFormValue {
  name: string;
  desc: string;
  status: number;
  task_category: number;
  internal_priority: number;
  task_effort: number;
  assignees: Boxed<number[]>;
  discussion_link: string;
  drivers: Boxed<{
    marketing: number;
    sustainability: number;
    ux: number;
  }>;
}

export type TaskAddFormFormState = FormState<TaskAddFormFormValue>;

@Injectable({ providedIn: 'root' })
export class TaskAddFormForm extends ZefForm<TaskAddFormFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        name: undefined,
        desc: undefined,
        status: 1,
        task_category: 13,
        internal_priority: 1,
        discussion_link: undefined,
        task_effort: 7,
        assignees: box([]),
        drivers: box({
          sustainability: 0,
          marketing: 0,
          ux: 0
        })
      },
      {
        name: validate(required)
      }
    );
  }
}
