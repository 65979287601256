<sat-popover
  [autoFocus]="false"
  [restoreFocus]="false"
  [verticalAlign]="'below'"
  [horizontalAlign]="'start'"
  hasBackdrop
  [zuiPopState]="featureName"
  (backdropClicked)="popRef.close(); _value = {}">
  <mat-card class="__card  c-soft-elevation">
    <zg-drivers-form-field (valueChange)="valueChange($event)" [value]="state.driversValue"></zg-drivers-form-field>

    <div class="__button-wrap">
      <button
        class="c-button--full"
        type="button"
        (click)="onUpdate$.next(); popRef.close()"
        mat-flat-button
        color="accent">
        Apply
      </button>
    </div>

  </mat-card>
</sat-popover>
