<!-- Filter -->
<div
  *ngIf="isSearchable"
  class="__search">
  <mat-form-field
    class="__search-field"
    color="accent">

    <mat-label>Search</mat-label>

    <input
      matInput
      type="text"
      [placeholder]="filterPlaceholder ? filterPlaceholder : ''"
      (keyup)="searchTable($event)"
    />

  </mat-form-field>
</div>

<!-- Table -->
<zef-scrollbar
  class="__scroll"
  [class.has-search]="isSearchable"
  maxHeight="calc(100vh - 500px)">
  <table
    class="__table  c-table-table"
    mat-table
    [dataSource]="tableDataSource"
    [trackBy]="trackBy"
    matSort
    (matSortChange)="sortTable($event)">

    <!-- Link -->
    <ng-container matColumnDef="_link" sticky>

      <th
        mat-header-cell
        *matHeaderCellDef>
      </th>

      <td
        mat-cell
        *matCellDef="let item">
        <div class="__link">
          <ng-template
            [ngTemplateOutletContext]="{ $implicit: item }"
            [ngTemplateOutlet]="linkTemplate">
          </ng-template>
        </div>
      </td>

    </ng-container>

    <ng-container
      *ngFor="let tableColumn of tableColumns"
      [matColumnDef]="tableColumn.name">

      <!-- Sortable column header -->
      <ng-container *ngIf="!tableColumn.isSortable || (tableColumn.isSortable && !tableColumn.isSortable === true); else notSortable">
        <th
          mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="tableColumn.name"
          [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'"
          [disabled]="tableColumn.name === 'actions' ? true : false">
          {{ tableColumn.name === 'actions' ? '' : tableColumn.name }}
        </th>
      </ng-container>

      <!-- Not sortable column header -->
      <ng-template #notSortable>
        <th
          mat-header-cell
          *matHeaderCellDef
          [class.text-right]="tableColumn.position === 'right'">
          {{ tableColumn.name }}
        </th>
      </ng-template>

      <!-- Column data -->
      <td
        mat-cell
        *matCellDef="let element"
        [class.text-right]="tableColumn.position === 'right'">

        <ng-container *ngIf="cellTemplatesMap[tableColumn.key]; else noTemplateRef">
          <ng-template
            [ngTemplateOutletContext]="{ $implicit: element }"
            [ngTemplateOutlet]="cellTemplatesMap[tableColumn.key]">
          </ng-template>
        </ng-container>

        <ng-template #noTemplateRef>
          {{ (element | nestedPath: tableColumn.key) || '-' }}
        </ng-template>

      </td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</zef-scrollbar>

<!-- Pagination -->
<mat-paginator
  *ngIf="isPageable"
  class="__paginator"
  [pageSizeOptions]="paginationSizes"
  [pageSize]="defaultPageSize"
  showFirstLastButtons
  [color]="'accent'">
</mat-paginator>