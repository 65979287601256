import { createAction, props, union } from '@ngrx/store';

export const generateSlugMap = createAction(
  '[@zerops/zemag/core/slug-state] generate-map',
  props<{ items: any[]; key: string; }>()
);

const all = union({ generateSlugMap });
export type SlugStateActionUnion = typeof all;

