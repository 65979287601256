import { NgModule } from '@angular/core';
import { ZefPermissionService } from '@zerops/zef/permission';
import { zefSelectAuthState, ZefAuthState } from '@zerops/zef/auth';
import { Store, select } from '@ngrx/store';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { AppState } from './app.model';

export enum Roles {
  Authorized = 'authorized',
  Unauthorized = 'unauthorized',
}

@NgModule({})
export class AppPermissionsModule {
  constructor(
    private _store: Store<AppState>,
    private _permissions: ZefPermissionService
  ) {

    // # Permissions Setup
    // base roles
    this._permissions.define(
      Roles.Authorized,
      () => this._store.pipe(select(zefSelectAuthState)).pipe(
        map((state) => state === ZefAuthState.Authorized),
        distinctUntilChanged()
      )
    );

    this._permissions.define(
      Roles.Unauthorized,
      () => this._store.pipe(select(zefSelectAuthState)).pipe(
        map((state) => state !== ZefAuthState.Authorized),
        distinctUntilChanged()
      )
    );

  }
}
