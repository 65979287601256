import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { PopStateModule } from '@zerops/zui/pop-state';
import { CategoryPopFeature } from './category-pop.feature';
import { CategoryPopEffect } from './category-pop.effect';

@NgModule({
  declarations: [ CategoryPopFeature ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ CategoryPopEffect ]),
    SatPopoverModule,
    MatCardModule,
    MatListModule,
    ActionListItemModule,
    PopStateModule
  ],
  exports: [ CategoryPopFeature ]
})
export class CategoryPopModule {

}
