import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { ASSIGNEES_POP_FEATURE_NAME } from '@zerops/zemag/features/assignees-pop';
import { CATEGORY_POP_FEATURE_NAME } from '@zerops/zemag/features/category-pop';
import { EFFORT_POP_FEATURE_NAME } from '@zerops/zemag/features/effort-pop';
import { INTERNAL_PRIORITY_POP_FEATURE_NAME } from '@zerops/zemag/features/internal-priority-pop';
import { PRIORITY_SCORE_POP_FEATURE_NAME } from '@zerops/zemag/features/priority-score-pop';
import { STATUS_POP_FEATURE_NAME } from '@zerops/zemag/features/status-pop';
import { Clipboard } from '@angular/cdk/clipboard';
import { ZefSnackService } from '@zerops/zef/snack';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { TASK_MORE_POP_FEATURE_NAME } from '@zerops/zemag/features/task-more-pop';

@Component({
  selector: 'zg-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: [ './task-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskItemComponent {
  @Input()
  id: number;

  @Input()
  name: string;

  @Input()
  desc: string;

  @Input()
  set assignees(value: any[]) {
    this._assignees = value;

    this.limitedAssignees = this._assignees.slice(0, 3);
  }

  @Input()
  internalPriority: any;

  @Input()
  status: any;

  @Input()
  category: any;

  @Input()
  author: any;

  @Input()
  effort: any;

  @Input()
  discussionLink: string;

  @Input()
  drivers: any;

  @Input()
  priorityScore: number;

  @Input()
  statusPopRef: SatPopover;

  @Input()
  effortPopRef: SatPopover;

  @Input()
  assigneesPopRef: SatPopover;

  @Input()
  categoryPopRef: SatPopover;

  @Input()
  internalPriorityPopRef: SatPopover;

  @Input()
  priorityScorePopRef: SatPopover;

  @Input()
  taskMorePopRef: SatPopover;

  @Output()
  update = new EventEmitter<any>();

  @Output()
  quickEditOpen = new EventEmitter<number>();

  @Output()
  nameClicked = new EventEmitter<any>();

  statusPopKey = STATUS_POP_FEATURE_NAME;
  effortPopKey = EFFORT_POP_FEATURE_NAME;
  categoryPopKey = CATEGORY_POP_FEATURE_NAME;
  internalPriorityPopKey = INTERNAL_PRIORITY_POP_FEATURE_NAME;
  priorityScorePopKey = PRIORITY_SCORE_POP_FEATURE_NAME;
  assigneesPopKey = ASSIGNEES_POP_FEATURE_NAME;
  taskMorePopKey = TASK_MORE_POP_FEATURE_NAME;

  limitedAssignees: any[];

  private _assignees: any[];

  constructor(
    private _clipboardService: Clipboard,
    private _snack: ZefSnackService
  ) { }

  trackByIndex(i: number) {
    return i;
  }


  trackById(_: number, itm: any) {
    return itm.id;
  }

  copy(val: string, copyButtonRef: MatButton) {
    this._clipboardService.copy(val);
    this._snack.info$({
      text: 'Copied to clipboard',
      actionButtonText: 'Close'
    }, { duration: 400 });

    setTimeout(() => {
      copyButtonRef._getHostElement().blur();
    }, 0);

  }

}
