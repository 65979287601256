import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZuiMaterialFormStackModule } from '@zerops/zui/material-form-stack';
import { TaskAddFormComponent } from './task-add-form.component';
import { CodeFieldModule } from '@zerops/zui/code-field';
import { DriversFormFieldModule } from '@zerops/zemag/common/drivers-form-field';

@NgModule({
  declarations: [ TaskAddFormComponent ],
  imports: [
    CommonModule,
    ZefFormsModule,
    ZuiMaterialFormStackModule,
    CodeFieldModule,
    DriversFormFieldModule
  ],
  exports: [ TaskAddFormComponent ]
})
export class TaskAddFormModule {

}
