import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ZuiLogoModule } from '@zerops/zui/logo';

import { LogoComponent } from './logo.component';

@NgModule({
  declarations: [ LogoComponent ],
  imports: [
    CommonModule,
    ZuiLogoModule
  ],
  exports: [ LogoComponent ]
})
export class LogoModule {

}
