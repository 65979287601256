import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ZefFormBase } from '@zerops/zef/forms';
import { TaskAddFormFormState } from './task-add-form.form';

@Component({
  selector: 'zg-task-add-form',
  templateUrl: './task-add-form.component.html',
  styleUrls: [ './task-add-form.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskAddFormComponent extends ZefFormBase<TaskAddFormFormState> {
  @Input()
  categories: any[];

  @Input()
  statuses: any[];

  @Input()
  internalPriorities: any[];

  @Input()
  users: any[];

  @Input()
  efforts: any[];

  @Output()
  editorInited = new EventEmitter<any>();

  trackByIndex(index: number) {
    return index;
  }
}
