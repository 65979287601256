<!-- Status pop -->
<sat-popover
  [autoFocus]="false"
  [restoreFocus]="false"
  [verticalAlign]="'below'"
  [horizontalAlign]="'end'"
  hasBackdrop
  [zuiPopState]="featureName"
  (backdropClicked)="popRef.close()">
  <mat-card class="__card  c-soft-elevation">
    <mat-action-list>

      <button
        class="__link"
        (click)="popRef.close(); onOpenDetail$.next();"
        mat-list-item>
        <zui-action-list-item [label]="'Edit task'" [icon]="'mode_edit'">
        </zui-action-list-item>
      </button>

      <button
        #deleteButtonRef="dynamicPopAnchor"
        (click)="deleteButtonRef.open()"
        [zuiDynamicPopAnchor]="deleteConfirmPopRef.popRef"
        mat-list-item>
        <zui-action-list-item warn [label]="'Remove task'" [icon]="'delete'">
        </zui-action-list-item>
      </button>

    </mat-action-list>
  </mat-card>

  <zui-confirm-pop
    #deleteConfirmPopRef
    [heading]="'Are you sure you want to remove the task?'"
    [confirmButtonText]="'Confirm'"
    [verticalAlign]="'below'"
    [closeButtonText]="'Close'"
    (confirm)="onDelete$.next(state.id); popRef.close();">
  </zui-confirm-pop>

</sat-popover>
