<div
  *ngIf="!!state.task?.id"
  class="__content">

  <div class="__bg" [style.background]="'linear-gradient(180deg, '+ state.task?.attributes.internal_priority?.data.attributes?.bg_color +' 0%, #efefef 100%)'"></div>

  <zui-wrap class="__wrap">

    <div class="__edit-wrap" *ngIf="state.editMode">

      <div class="__edit-top">

        <div>
          <button
            mat-button
            (click)="onCloseDetail$.next()"
            class="__back-button">
            <mat-icon>navigate_before</mat-icon> Back
          </button>
        </div>

        <div class="u-text--right">
          <button
            class="__save-button"
            type="button"
            (click)="onUpdate$.next()"
            mat-flat-button>
            Update task
          </button>
        </div>

      </div>

      <zef-form>

        <zef-form-field last class="__name-form-field">
          <mat-form-field color="accent">

            <mat-label>Name</mat-label>

            <input
              matInput
              type="name"
              [(ngModel)]="editValues.name"
              [placeholder]="'Short description of the task..'"
            />

          </mat-form-field>
        </zef-form-field>

        <zef-form-field last class="__discussion-form-field">
          <mat-form-field color="accent">

            <mat-label>Discussion link</mat-label>

            <input
              matInput
              type="name"
              [(ngModel)]="editValues.discussion_link"
              [placeholder]="'Link to a discussion, Discord, Slack, Redmine..'"
            />

          </mat-form-field>
        </zef-form-field>

        <zef-form-field class="__code-form-field">

          <zef-form-label class="__code-field-label">Description</zef-form-label>

            <div (paste)="onPaste($event)">
              <zui-code-field
                class="__code-field"
                [(ngModel)]="editValues.desc"
                (editorInit)="editorInit($event)"
                [editorOptions]="{
                  language: 'markdown',
                  lineNumbers: 'off',
                  theme: 'vs-light',
                  lineDecorationsWidth: 0,
                  renderLineHighlight: 'none',
                  fontSize: '14px',
                  padding: {
                    top: 14
                  },
                  wordWrap: 'on',
                  wordBasedSuggestions: false,
                  overviewRulerLanes: 0,
                  hideCursorInOverviewRuler: true,
                  overviewRulerBorder: false
                }">
              </zui-code-field>
            </div>
          </zef-form-field>

      </zef-form>

    </div>

    <ng-container *ngIf="!state.editMode">

      <zg-task-item
        [statusPopRef]="statusPopRef"
        [effortPopRef]="effortPopRef"
        [categoryPopRef]="categoryPopRef"
        [internalPriorityPopRef]="internalPriorityPopRef"
        [priorityScorePopRef]="priorityScorePopRef"
        [assigneesPopRef]="assigneesPopRef"
        [taskMorePopRef]="taskMorePopRef"
        [name]="state.task?.attributes.name"
        [assignees]="state.task?.attributes.assignees"
        [author]="state.task?.attributes.author?.data?.attributes"
        [desc]="state.task?.attributes.desc"
        [id]="state.task?.id"
        [discussionLink]="state.task?.attributes?.discussion_link"
        [effort]="state.task?.attributes?.task_effort?.data?.attributes"
        [internalPriority]="state.task?.attributes.internal_priority?.data.attributes"
        [status]="state.task?.attributes.status?.data.attributes"
        [drivers]="state.task?.attributes.driversObj"
        [category]="state.task?.attributes.task_category?.data.attributes"
        [priorityScore]="state.task?.attributes.priorityScore">
      </zg-task-item>

      <div class="__effort-entries">

        <div class="__effort-entries-control">

          <button
            mat-flat-button
            type="button"
            class="__effort-button"
            (click)="onAddEffortEntry$.next(4)">
            + 4 hours
          </button>

          <button
            mat-flat-button
            type="button"
            class="__effort-button"
            (click)="onAddEffortEntry$.next(8)">
            + 1 day
          </button>

          <button
            mat-flat-button
            class="__effort-button"
            type="button"
            (click)="onAddEffortEntry$.next(40)">
            + 1 week
          </button>

        </div>

        <div>
          <zui-separator [orientation]="'horizontal'" [spacing]="'none'"></zui-separator>
        </div>

        <button
          *ngIf="state.task?.id"
          type="button"
          mat-button
          class="__add-button"
          #addRecordRef="dynamicPopAnchor"
          (click)="addRecordRef.open()"
          [zuiDynamicPopAnchor]="effortEntryAddEditPopRef"
          [zefNgrxDialogOpen]="effortEntryAddEditPopKey"
          [zefNgrxDialogOpenMeta]="{ mode: 'add', taskId: state.task?.id }">
          <mat-icon>add</mat-icon>
          Add record
        </button>

        <div
          class="__effort-entry"
          *ngFor="let item of state.effortByPeople; trackBy: trackByIndex"
          #assigneeRef="dynamicPopAnchor"
          (click)="assigneeRef.open()"
          [zuiDynamicPopAnchor]="effortEntriesPopRef"
          [zefNgrxDialogOpen]="effortEntriesPopKey"
          [zefNgrxDialogOpenMeta]="{ taskId: state.task?.id, userId: item.id }">
          <img
            class="__effort-entry_avatar"
            [src]="item.avatar?.url"
            [alt]="item.username"
          />
          <span class="__effort-entry_hours">{{ item.effort }}</span>
        </div>

      </div>

      <div class="__markdown-wrap">
        <markdown class="__markdown  c-text-content" lineNumbers [data]="state.task?.attributes.desc"></markdown>
      </div>

    </ng-container>

  </zui-wrap>

</div>
