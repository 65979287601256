import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass, NgIf, NgFor, registerLocaleData } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import localeCs from '@angular/common/locales/cs';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { select, Store } from '@ngrx/store';
import keys from 'lodash-es/keys';
import omit from 'lodash-es/omit';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { SnackSuccessModule } from '@zerops/zui/snack-success';
import { SnackErrorModule } from '@zerops/zui/snack-error';
import { SnackInfoModule } from '@zerops/zui/snack-info';
import { ZefAuthState, zefLogout, zefSelectAuthData, zefSelectAuthState } from '@zerops/zef/auth';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import {
  selectZefNgrxRouterParams,
  selectZefNgrxRouterPath,
  selectZefNgrxRouterQueryParams,
  selectZefNgrxRouterUrl
} from '@zerops/zef/ngrx-router';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ZefErrorsModule } from '@zerops/zef/errors';
import { ZefSnackModule } from '@zerops/zef/snack';
import { FeatureModule } from '@zerops/zemag/features';
import { TaskItemModule } from '@zerops/zemag/common/task-item';
import { LogoModule } from '@zerops/zemag/common/logo';
import { TaskCategoryEntity } from '../core/task-category-base';
import { TaskInternalPriorityEntity } from '../core/task-internal-priority-base';
import { TaskStatusEntity } from '../core/task-status-base';
import { UsersEntity } from '../core/users-base';
import { AppPermissionsModule } from './app.permissions';
import { TASK_ADD_DIALOG_KEY, TASK_DETAIL_DIALOG_KEY, Views } from './app.constant';
import { fromParamsArray, toParamsArray } from './app.utils';
import { en } from './app.constant';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';

registerLocaleData(localeCs, 'cs');

@Component({
  standalone: true,
  selector: 'zg-app',
  templateUrl: './app.feature.html',
  styleUrls: ['./app.feature.scss'],
  imports: [
    NgIf,
    NgFor,
    NgClass,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    FeatureModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    AppPermissionsModule,
    ZefPipesModule,
    TaskItemModule,
    ZuiSeparatorComponent,
    ZefDialogModule,
    LogoModule,
    SatPopoverModule,
    ActionListItemModule,
    ZefErrorsModule,
    ZefSnackModule,
    SnackSuccessModule,
    SnackErrorModule,
    SnackInfoModule,
    ZefScrollbarComponent
  ]
})
export class AppFeature extends ZefReactiveComponent {

  // # Event Streams
  onLogout$ = new Subject<void>();
  onCloseDialog$ = new Subject<void>();

  // # Data
  // -- sync
  views = Views;

  addDialogKey = TASK_ADD_DIALOG_KEY;
  detailDialogKey = TASK_DETAIL_DIALOG_KEY;

  // -- async
  routerParams$ = this._store.pipe(
    select(selectZefNgrxRouterParams)
  );
  hasFilters$ = this._store.pipe(
    select(selectZefNgrxRouterPath),
    map((d) => d?.includes('dashboard/')),
    distinctUntilChanged()
  );
  cleanRouterParams$ = this.routerParams$.pipe(
    map((d) => omit(d, ['view', 'id']))
  );
  isAuthorized$ = this._store.pipe(
    select(zefSelectAuthState),
    map((s) => s === ZefAuthState.Authorized),
    distinctUntilChanged()
  );
  authorizedUser$ = this._store.pipe(
    select(zefSelectAuthData),
    map((d) => d?.userId),
    distinctUntilChanged(),
    filter((d) => !!d),
    switchMap((d) => this._userEntity.rawEntities$().pipe(
      map((e) => e?.[d]),
      filter((d) => !!d),
      take(1)
    ))
  );
  addDialogOpen$ = this._store.pipe(
    select(selectZefDialogState(this.addDialogKey)),
    map((data) => data.state)
  );
  detailDialogOpen$ = this._store.pipe(
    select(selectZefDialogState(this.detailDialogKey)),
    map((data) => data.state)
  );
  detailDialogMeta$ = this._store.pipe(
    select(selectZefDialogState(this.detailDialogKey)),
    map((data) => data.meta)
  );
  routerUrl$ = this._store.pipe(
    select(selectZefNgrxRouterUrl),
    filter((d) => !!d),
    map((d) => d.split(';')[0])
  );
  queryParams$ = this._store.pipe(
    select(selectZefNgrxRouterQueryParams)
  );
  activeInternalPriorities$ = this.routerParams$.pipe(
    map((param) => param?.internalPriorities ? fromParamsArray(param.internalPriorities) : [])
  );
  activeStatuses$ = this.routerParams$.pipe(
    map((param) => param?.activeStatuses ? fromParamsArray(param.activeStatuses) : [])
  );
  activeCategories$ = this.routerParams$.pipe(
    map((param) => param?.activeCategories ? fromParamsArray(param.activeCategories) : [])
  );
  showAs$ = this.routerParams$.pipe(
    map((d) => d?.view)
  );
  taskInternalPriorities$ = this._taskInternalPriorityEntity.list$();
  users$ = this._userEntity.list$().pipe(map((d) => d?.filter((itm) => !itm.archived)));
  taskStatuses$ = this._taskStatusEntity.list$();
  taskCategories$ = this._taskCategoryEntity.list$();

  // # State resolver
  state = this.$connect({
    users: this.users$,
    hasFilters: this.hasFilters$,
    queryParams: this.queryParams$,
    cleanRouterParams: this.cleanRouterParams$,
    activeInternalPriorities: this.activeInternalPriorities$,
    activeStatuses: this.activeStatuses$,
    activeCategories: this.activeCategories$,
    taskStatuses: this.taskStatuses$,
    taskCategories: this.taskCategories$,
    routerParams: this.routerParams$,
    taskInternalPriorities: this.taskInternalPriorities$,
    showAs: this.showAs$,
    routerUrl: this.routerUrl$,
    addDialogOpen: this.addDialogOpen$,
    detailDialogOpen: this.detailDialogOpen$,
    detailDialogMeta: this.detailDialogMeta$,
    isAuthorized: this.isAuthorized$,
    authorizedUser: this.authorizedUser$
  });

  // # Action Streams
  private _logoutAction$ = this.onLogout$.pipe(
    map((_) => zefLogout()
  ));
  private _closeDialogAction$ = this.onCloseDialog$.pipe(
    map((_) => zefDialogClose({ key: this.detailDialogKey })
  ));

  constructor(
    private _taskStatusEntity: TaskStatusEntity,
    private _taskInternalPriorityEntity: TaskInternalPriorityEntity,
    private _taskCategoryEntity: TaskCategoryEntity,
    private _translationsService: ZefTranslationsService,
    private _userEntity: UsersEntity,
    private _store: Store<any>,
    public _activeRoute: ActivatedRoute
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._logoutAction$,
      this._closeDialogAction$
    ]);

    // general translations
    this._translationsService.setTranslations('general', { en });

  }

  _paramsWithout(params: object, value: object) {
    const key = keys(value)[0];

    return {
      ...params,
      [key]: toParamsArray([
        ...fromParamsArray(params[key]).filter((s) => s !== value[key])
      ])
    };
  }

  _paramsWith(params: object, value: object) {
    const key = keys(value)[0];

    if (!params) { return value; }

    return {
      ...params,
      [key]: toParamsArray([
        ...fromParamsArray(params[key]),
        value[key]
      ])
    };
  }

}
