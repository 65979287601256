import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFeature } from './search.feature';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZuiMaterialFormStackModule } from '@zerops/zui/material-form-stack';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { TaskItemModule } from '@zerops/zemag/common/task-item';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ SearchFeature ],
  imports: [
    CommonModule,
    ZefDialogModule,
    MatCardModule,
    ZefFormsModule,
    ZuiMaterialFormStackModule,
    TaskItemModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [ SearchFeature ]
})
export class SearchModule {

}
