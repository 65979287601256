import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZefCopyToClipboardModule } from '@zerops/zef/copy-to-clipboard';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { ZuiMoreButtonModule } from '@zerops/zui/more-button';
import { TaskItemComponent } from './task-item.component';

@NgModule({
  declarations: [ TaskItemComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    DynamicPopAnchorModule,
    ZefDialogModule,
    ZefCopyToClipboardModule,
    ZuiMoreButtonModule
  ],
  exports: [ TaskItemComponent ]
})
export class TaskItemModule {
}
