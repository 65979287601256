import { NgModule } from '@angular/core';
import { AssigneesPopModule } from './assignees-pop';
import { CategoryPopModule } from './category-pop';
import { EffortPopModule } from './effort-pop';
import { TaskDetailModule } from './task-detail';
import { EffortEntriesPopModule } from './effort-entries-pop';
import { EffortEntryAddEditPopModule } from './effort-entry-add-edit-pop';
import { InternalPriorityPopModule } from './internal-priority-pop';
import { PriorityScorePopModule } from './priority-score-pop';
import { StatusPopModule } from './status-pop';
import { TaskMorePopModule } from './task-more-pop';
import { SearchModule } from './search';
import { TaskAddModule } from './task-add';

@NgModule({
  exports: [
    StatusPopModule,
    EffortPopModule,
    EffortEntriesPopModule,
    EffortEntryAddEditPopModule,
    CategoryPopModule,
    InternalPriorityPopModule,
    PriorityScorePopModule,
    AssigneesPopModule,
    TaskMorePopModule,
    TaskDetailModule,
    SearchModule,
    TaskAddModule
  ]
})
export class FeatureModule {}
