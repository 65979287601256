import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriorityScorePopFeature } from './priority-score-pop.feature';
import { EffectsModule } from '@ngrx/effects';
import { PriorityScorePopEffect } from './priority-score-pop.effect';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { PopStateModule } from '@zerops/zui/pop-state';
import { DriversFormFieldModule } from '@zerops/zemag/common/drivers-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [ PriorityScorePopFeature ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ PriorityScorePopEffect ]),
    SatPopoverModule,
    MatCardModule,
    MatListModule,
    ActionListItemModule,
    PopStateModule,
    DriversFormFieldModule,
    MatButtonModule
  ],
  exports: [ PriorityScorePopFeature ]
})
export class PriorityScorePopModule {

}
