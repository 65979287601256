import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { map, filter, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ApiEntityKeys } from '@zerops/zemag/app';
import { generateSlugMap } from '@zerops/zemag/core/slug-state';
import { ZefSnackService } from '@zerops/zef/snack';
import { ZefAuthState, zefSelectAuthState } from '@zerops/zef/auth';
import { select, Store } from '@ngrx/store';
import { TasksEntity } from './tasks-base.entity';
import { successOf } from '@zerops/zef/entities';

@Injectable()
export class TasksBaseEffect {

  isAuthorized$ = this._store.pipe(
    select(zefSelectAuthState),
    filter((s) => s === ZefAuthState.Authorized)
  );

  private _onInit$ = createEffect(() => this.isAuthorized$.pipe(
    map(() => this._entity.searchAll({
      sort: this._entity.defaultApiSort,
      populate: 'author,author.avatar.*,drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort',
      'pagination[start]': 0,
      'pagination[limit]': 10000
    }))
  ));

  private _onListChange$ = createEffect(() => this._entity
    .all$()
    .pipe(
      filter((d) => !!(!!d && d.length)),
      distinctUntilChanged((a, b) => a?.length === b?.length),
      map((items) => generateSlugMap({ items, key: ApiEntityKeys.Tasks }))
    )
  );

  private _onUpdateSuccess$ = this._actions$.pipe(
    successOf<any>(this._entity.updateOne)
  );

  private _onAddSuccess$ = this._actions$.pipe(
    successOf<any>(this._entity.addOne)
  );

  private _onUpdateSuccessReloadEntity$ = createEffect(() => this._onUpdateSuccess$.pipe(
    map((d) => this._entity.getOne(
      d.originalAction.data.id,
      {
        strapiPopulate: {
          populate: 'author,author.avatar.*,drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort',
        }
      }
    ))
  ));

  private _onAddSuccessReloadEntity$ = createEffect(() => this._onAddSuccess$.pipe(
    map((d) => this._entity.getOne(
      d.data.items.result[0],
      {
        strapiPopulate: {
          populate: 'author,author.avatar.*,drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort',
        }
      }
    ))
  ));


  private _onUpdateSuccessNotification$ = createEffect(() => this._onUpdateSuccess$.pipe(
    switchMap(() => this._snack.success$({
      text: `Task updated`,
      actionButtonText: 'Close'
    }, { duration: 800 }))
  ), { dispatch: false });

  private _onAddSuccessNotification$ = createEffect(() => this._onAddSuccess$.pipe(
    switchMap(() => this._snack.success$({
      text: `Task added`,
      actionButtonText: 'Close'
    }, { duration: 1500 }))
  ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _entity: TasksEntity,
    private _store: Store<any>,
    private _snack: ZefSnackService
  ) { }

}
