import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { SatPopover } from '@zerops/zef/popover';
import { TaskInternalPriorityEntity } from '@zerops/zemag/core/task-internal-priority-base';
import { TasksEntity } from '@zerops/zemag/core/tasks-base';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FEATURE_NAME } from './internal-priority-pop.constant';
import { InternalPriorityPopService } from './internal-priority-pop.service';

@Component({
  selector: 'zg-internal-priority-pop',
  templateUrl: './internal-priority-pop.feature.html',
  styleUrls: [ './internal-priority-pop.feature.scss' ]
})
export class InternalPriorityPopFeature extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams
  onUpdate$ = new Subject<{ id: number; data: any; }>();

  // # Data
  // -- sync
  featureName = FEATURE_NAME;

  // -- angular
  @ViewChild(SatPopover, { static: true })
  popRef: SatPopover;

  // -- async
  taskInternalPriorities$ = this._taskInternalPrioritiesEntity.list$();
  popState$ = this._store.pipe(
    select(selectZefDialogState(this.featureName))
  );
  id$ = this.popState$.pipe(
    map((d) => d.meta as number),
    distinctUntilChanged()
  );

  // # State resolver
  state = this.$connect({
    taskInternalPriorities: this.taskInternalPriorities$,
    popState: this.popState$,
    id: this.id$
  });

  // # Action Streams
  private _updateAction$ = this.onUpdate$.pipe(
    map(({ id, data }) => this._tasksEntity.updateOne(
      id,
      { data },
      {
        strapiPopulate: {
          populate: 'drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort'
        }
      },
      {
        type: 'snack'
      }
    ))
  );

  constructor(
    private _tasksEntity: TasksEntity,
    private _taskInternalPrioritiesEntity: TaskInternalPriorityEntity,
    private _internalPriorityPopService: InternalPriorityPopService,
    private _store: Store<any>
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([ this._updateAction$ ]);

  }

  ngAfterViewInit() {
    this._internalPriorityPopService.saveRef(this.popRef);
  }

}
