import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TaskCategoryBaseEffect } from './task-category-base.effect';

@NgModule({
  imports: [ EffectsModule.forFeature([ TaskCategoryBaseEffect ]) ]
})
export class TaskCategoryBaseModule {

}
