import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { firstAvailable } from '@zerops/zef/core';
import { zefDialogClose, zefDialogOpen } from '@zerops/zef/dialog';
import { successOf } from '@zerops/zef/entities';
import { EffortEntryEntity } from '@zerops/zemag/core/effort-entry-base';
import { filter, map, switchMap } from 'rxjs/operators';
import { FEATURE_NAME } from './effort-entry-add-edit-pop.constant';
import { EffortEntryAddEditPopForm } from './effort-entry-add-edit-pop.form';

@Injectable()
export class EffortEntryAddEditPopEffect {

  private _onEditDialogOpenSetFormValue$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogOpen),
    filter((action) => action?.key === FEATURE_NAME && action.meta?.mode === 'edit' && action.meta?.effortEntryId),
    switchMap(({ meta }) => this._effortEntryEntity
      .entityById$(meta.effortEntryId)
      .pipe(
        firstAvailable(),
        map((d) => this._effortEntryAddEditPopForm.setValue({
          createdAt: d.attributes.created || d.attributes.createdAt,
          effort: d.attributes.hour_effort
        }))
      )
    )
  ));
  private _onAddDialogOpenSetFormValue$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogOpen),
    filter((action) => action?.key === FEATURE_NAME && action.meta?.mode === 'add'),
    map(() => this._effortEntryAddEditPopForm.setValue({
      createdAt: new Date().toISOString(),
      effort: undefined
    }))
  ));

  private _onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    switchMap(() => [
      this._effortEntryAddEditPopForm.reset(),
      this._effortEntryAddEditPopForm.setDefaultValues()
    ])
  ));

  private _onAddSuccesDialogClose$ = createEffect(() => this._actions$.pipe(
    successOf(this._effortEntryEntity.addOne),
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  private _onUpdateSuccessDialogClose$ = createEffect(() => this._actions$.pipe(
    successOf(this._effortEntryEntity.updateOne),
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  constructor(
    private _actions$: Actions,
    private _effortEntryAddEditPopForm: EffortEntryAddEditPopForm,
    private _effortEntryEntity: EffortEntryEntity
  ) { }

}
