<zef-form
  [state]="state"
  (submit)="submit.emit()">
  <div class="__grid">

    <div class="__grid-column  is-left">

      <div class="__left-content">

        <zef-form-field>
          <mat-form-field color="accent">

            <mat-label>Name</mat-label>

            <input
              matInput
              type="text"
              name="name"
              [placeholder]="'Short description of the task..'"
              [ngrxEnableFocusTracking]="true"
              [ngrxFormControlState]="state?.controls.name"
            />
          </mat-form-field>

        </zef-form-field>

        <zef-form-field>
          <mat-form-field class="__discussion-form-field" color="accent">

            <mat-label>Discussion link</mat-label>

            <input
              matInput
              type="text"
              [placeholder]="'Link to a discussion, Discord, Slack, Redmine..'"
              [ngrxEnableFocusTracking]="true"
              [ngrxFormControlState]="state?.controls.discussion_link"
            />

          </mat-form-field>

        </zef-form-field>

        <zef-form-field class="__code-form-field">

          <zef-form-label class="__code-field-label">Description</zef-form-label>

          <zui-code-field
            class="__code-field"
            (editorInit)="editorInited.emit($event)"
            [ngrxFormControlState]="state?.controls.desc"
            [ngrxEnableFocusTracking]="true"
            [editorOptions]="{
              language: 'markdown',
              lineNumbers: 'off',
              theme: 'vs-light',
              lineDecorationsWidth: 0,
              renderLineHighlight: 'none',
              fontSize: '14px',
              wordWrap: 'on',
              padding: {
                top: 14
              },

              wordBasedSuggestions: false,
              overviewRulerLanes: 0,
              hideCursorInOverviewRuler: true,
              overviewRulerBorder: false
            }">
          </zui-code-field>

        </zef-form-field>
      </div>

      <div class="__button-wrap">
        <ng-content></ng-content>
      </div>

    </div>

    <div class="__grid-column  is-right">
      <div class="__column-scroll">

        <zef-form-field [appearance]="'button-toggle'">

          <zef-form-label>Category</zef-form-label>

          <mat-button-toggle-group
            [ngrxEnableFocusTracking]="true"
            [ngrxFormControlState]="state?.controls.task_category">

            <mat-button-toggle
              *ngFor="let item of categories; trackBy: trackByIndex;"
              [value]="item.id">{{ item.attributes?.name }}</mat-button-toggle>

          </mat-button-toggle-group>

        </zef-form-field>

        <zef-form-field [appearance]="'button-toggle'">

          <zef-form-label>Status</zef-form-label>

          <mat-button-toggle-group
            [ngrxEnableFocusTracking]="true"
            [ngrxFormControlState]="state?.controls.status">

            <mat-button-toggle
              *ngFor="let item of statuses; trackBy: trackByIndex;"
              [value]="item.id">{{ item.attributes?.name }}</mat-button-toggle>

          </mat-button-toggle-group>

        </zef-form-field>

        <zef-form-field [appearance]="'button-toggle'">

          <zef-form-label>Internal priority</zef-form-label>

          <mat-button-toggle-group
            [ngrxEnableFocusTracking]="true"
            [ngrxFormControlState]="state?.controls.internal_priority">

            <mat-button-toggle
              *ngFor="let item of internalPriorities; trackBy: trackByIndex;"
              [value]="item.id">

              <span
                [style.backgroundColor]="item.attributes?.status_color"
                class="__status-icon"
                [ngClass]="'is-' + item.attributes?.slug">
              </span>

              {{ item.attributes?.name }}

            </mat-button-toggle>

          </mat-button-toggle-group>

        </zef-form-field>

        <zef-form-field class="__drivers-field">

          <zef-form-label>Drivers</zef-form-label>

          <zg-drivers-form-field
            [ngrxEnableFocusTracking]="true"
            [ngrxFormControlState]="state?.controls.drivers">
          </zg-drivers-form-field>

        </zef-form-field>

        <zef-form-field [appearance]="'button-toggle'">

          <zef-form-label>Effort</zef-form-label>

          <mat-button-toggle-group
            [ngrxEnableFocusTracking]="true"
            [ngrxFormControlState]="state?.controls.task_effort">

            <mat-button-toggle
              *ngFor="let item of efforts; trackBy: trackByIndex;"
              [value]="item.id">

              {{ item.attributes?.name }}

            </mat-button-toggle>

          </mat-button-toggle-group>

        </zef-form-field>

        <zef-form-field [appearance]="'button-toggle'">

          <zef-form-label>Assignees</zef-form-label>

          <mat-button-toggle-group
            multiple
            [ngrxEnableFocusTracking]="true"
            [ngrxFormControlState]="state?.controls.assignees">

            <mat-button-toggle
              class="__user-toggle"
              *ngFor="let item of users; trackBy: trackByIndex;"
              [value]="item.id">

              <div class="__user-avatar">
                <img
                  class="__user-avatar_image"
                  [src]="item.avatar?.url"
                  [alt]="item.username"
                />
              </div>

              {{ item?.username }}

            </mat-button-toggle>

          </mat-button-toggle-group>

        </zef-form-field>

      </div>
    </div>

  </div>

</zef-form>
