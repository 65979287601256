import { InjectionToken } from '@angular/core';

export const en = {
  close: 'Close',
  true: 'Yes',
  false: 'No',
  clientStatuses: {
    ACTIVE: 'Active'
  }
};

export type GeneralTranslations = typeof en;

export enum RouteKeys {}

export const TASK_ADD_DIALOG_KEY = 'task-add';
export const TASK_DETAIL_DIALOG_KEY = 'task-detail';

export enum Views {
  Categories = 'categories',
  Kanban = 'kanban',
  Team = 'team'
}

export enum ApiEntityKeys {
  Tasks = 'tasks',
  TaskStatus = 'task-statuses',
  TaskEffort = 'task-efforts',
  TaskInternalPriority = 'task-internal-priorities',
  TaskCategory = 'task-categories',
  Users = 'users',
  Drivers = 'drivers',
  EffortEntry = 'effort-entries'
}

export const DEFAULT_APP_ROUTE = [ '/dashboard', Views.Team ];

export const API_ENDPOINT = new InjectionToken<string>('zemag-gui-api-endpoint');
