<sat-popover
  [autoFocus]="false"
  [restoreFocus]="false"
  [verticalAlign]="'below'"
  [horizontalAlign]="'start'"
  hasBackdrop
  [zuiPopState]="featureName"
  (backdropClicked)="popRef.close()">
  <mat-card class="__card  c-soft-elevation">
    <mat-action-list>
      <button
        *ngFor="let item of state.taskCategories; trackBy: trackByIndex;"
        (click)="onUpdate$.next({
          id: state.id,
          data: { task_category: item.id }
        }); popRef.close();"
        mat-list-item>
        <zui-action-list-item [label]="item.attributes?.name">
        </zui-action-list-item>
      </button>
    </mat-action-list>
  </mat-card>
</sat-popover>
