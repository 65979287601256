import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { SatPopover } from '@zerops/zef/popover';
import { TaskEffortEntity } from '@zerops/zemag/core/task-effort-base';
import { TasksEntity } from '@zerops/zemag/core/tasks-base';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FEATURE_NAME } from './effort-pop.constant';
import { EffortPopService } from './effort-pop.service';

@Component({
  selector: 'zg-effort-pop',
  templateUrl: './effort-pop.feature.html',
  styleUrls: [ './effort-pop.feature.scss' ]
})
export class EffortPopFeature extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams
  onUpdate$ = new Subject<{ id: number; data: any; }>();

  // # Data
  // -- sync
  featureName = FEATURE_NAME;

  // -- angular
  @ViewChild(SatPopover, { static: true })
  popRef: SatPopover;

  // -- async
  taskEfforts$ = this._taskEffortEntity.list$();
  popState$ = this._store.pipe(
    select(selectZefDialogState(this.featureName))
  );
  id$ = this.popState$.pipe(
    map((d) => d.meta as number),
    distinctUntilChanged()
  );

  // # State resolver
  state = this.$connect({
    taskEfforts: this.taskEfforts$,
    popState: this.popState$,
    id: this.id$
  });

  // # Action Streams
  private _updateAction$ = this.onUpdate$.pipe(
    map(({ id, data }) => this._tasksEntity.updateOne(
      id,
      { data },
      {
        strapiPopulate: {
          populate: 'drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort'
        }
      },
      {
        type: 'snack'
      }
    ))
  );

  constructor(
    private _tasksEntity: TasksEntity,
    private _taskEffortEntity: TaskEffortEntity,
    private _effortPopService: EffortPopService,
    private _store: Store<any>
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([ this._updateAction$ ]);

  }

  ngAfterViewInit() {
    this._effortPopService.saveRef(this.popRef);
  }

}
