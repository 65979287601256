import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyNativeDateModule as MatNativeDateModule } from '@angular/material/legacy-core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ZefFormsModule } from '@zerops/zef/forms';
import { PopStateModule } from '@zerops/zui/pop-state';
import { ZuiMaterialFormStackModule } from '@zerops/zui/material-form-stack';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { EffectsModule } from '@ngrx/effects';
import { EffortEntryAddEditPopFeature } from './effort-entry-add-edit-pop.feature';
import { EffortEntryAddEditPopEffect } from './effort-entry-add-edit-pop.effect';

@NgModule({
  declarations: [ EffortEntryAddEditPopFeature ],
  imports: [
    CommonModule,
    SatPopoverModule,
    MatCardModule,
    EffectsModule.forFeature([ EffortEntryAddEditPopEffect ]),
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    RouterModule,
    PopStateModule,
    ZefFormsModule,
    ZuiMaterialFormStackModule,
    ZefProgressErrorModule
  ],
  exports: [ EffortEntryAddEditPopFeature ],
  providers: [ MatDatepickerModule ]
})
export class EffortEntryAddEditPopModule {

}
