import { Task } from './tasks-base.model';

function _getPriority(
  importance: number,
  value: number
) {
  const baseMaxValue = 100 * (importance / 100);
  return (value / 5) * baseMaxValue;
}

export function transformTaskItem(data: Task) {

  let priorityScore = 0;

  const driversObj: any = data.attributes?.drivers?.reduce((obj, itm) => {

    if (itm?.driver?.data) {
      obj[itm.driver.data.attributes.slug] = {
        value: itm.value,
        slug: itm.driver.data.attributes.slug,
        name: itm.driver.data.attributes.name,
        id: itm.id,
        importance: itm.driver.data.attributes.importance || 0
      };
    }

    return obj;
  }, {});

  if (driversObj.ux) {
    priorityScore = priorityScore + _getPriority(
      driversObj.ux.importance,
      driversObj.ux.value
    );
  }

  if (driversObj.marketing) {
    priorityScore = priorityScore + _getPriority(
      driversObj.marketing.importance,
      driversObj.marketing.value
    );
  }

  if (driversObj.sustainability) {
    priorityScore = priorityScore + _getPriority(
      driversObj.sustainability.importance,
      driversObj.sustainability.value
    );
  }

  return {
    ...data,
    attributes: {
      ...data.attributes,
      driversObj,
      priorityScore
    }
  };
}
