export interface Driver {
  name: string;
  importance: number;
  slug: string;
}

export enum DriverIds {
  Marketing = 4,
  Ux = 1,
  Sustainability = 7
}
