import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { PopStateModule } from '@zerops/zui/pop-state';
import { EffortEntriesTableModule } from '@zerops/zemag/features/effort-entries-table';
import { EffortEntriesPopFeature } from './effort-entries-pop.feature';

@NgModule({
  declarations: [ EffortEntriesPopFeature ],
  imports: [
    CommonModule,
    SatPopoverModule,
    MatCardModule,
    MatListModule,
    RouterModule,
    ActionListItemModule,
    PopStateModule,
    EffortEntriesTableModule
  ],
  exports: [ EffortEntriesPopFeature ]
})
export class EffortEntriesPopModule {

}
