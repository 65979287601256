import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { SatPopover } from '@zerops/zef/popover';
import { DriverIds, DriversEntity } from '@zerops/zemag/core/drivers-base';
import { TasksEntity, transformTaskItem, TaskSynthetic } from '@zerops/zemag/core/tasks-base';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './priority-score-pop.constant';
import { PriorityScorePopService } from './priority-score-pop.service';

@Component({
  selector: 'zg-priority-score-pop',
  templateUrl: './priority-score-pop.feature.html',
  styleUrls: [ './priority-score-pop.feature.scss' ]
})
export class PriorityScorePopFeature extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams
  onUpdate$ = new Subject<{ id: number; data: any; } | void>();

  // # Data
  // -- sync
  featureName = FEATURE_NAME;
  _value: any = {};

  // -- angular
  @ViewChild(SatPopover, { static: true })
  popRef: SatPopover;

  // -- async
  drivers$ = this._driversEntity.list$();
  popState$ = this._store.pipe(
    select(selectZefDialogState(this.featureName))
  );
  id$ = this.popState$.pipe(
    map((d) => d.meta as number),
    distinctUntilChanged()
  );
  task$ = this.id$.pipe(
    switchMap((id) => id
      ? this._tasksEntity
        .entityById$(id)
        .pipe(
          filter((d) => !!d),
          map((d) => transformTaskItem(d))
        )
      : of({}) as Observable<TaskSynthetic>
    )
  );
  driversValue$ = this.task$.pipe(
    map((d) => ({
      ux: d.attributes?.driversObj?.ux?.value || 0,
      marketing: d.attributes?.driversObj?.marketing?.value || 0,
      sustainability: d.attributes?.driversObj?.sustainability?.value || 0
    }))
  );

  // # State resolver
  state = this.$connect({
    taskEfforts: this.drivers$,
    popState: this.popState$,
    id: this.id$,
    task: this.task$,
    driversValue: this.driversValue$
  });

  // # Action Streams
  private _updateAction$ = this.onUpdate$.pipe(
    withLatestFrom(this.task$),
    map(([ _, task ]) => {

      const data = [];
      if (task.attributes?.driversObj?.ux?.id) {
        data.push({
          id: task.attributes?.driversObj?.ux?.id,
          driver: DriverIds.Ux,
          value: this._value?.ux || 0
        });
      } else {
        data.push({
          id: task.attributes?.driversObj?.ux?.id,
          driver: DriverIds.Ux,
          value: this._value?.ux || 0
        });
      }

      if (task.attributes?.driversObj?.marketing?.id) {
        data.push({
          id: task.attributes?.driversObj?.marketing?.id,
          driver: DriverIds.Marketing,
          value: this._value?.marketing || 0
        });
      } else {
        data.push({
          id: task.attributes?.driversObj?.marketing?.id,
          driver: DriverIds.Marketing,
          value: this._value?.marketing || 0
        });
      }

      if (task.attributes?.driversObj?.sustainability?.id) {
        data.push({
          id: task.attributes?.driversObj?.sustainability?.id,
          driver: DriverIds.Sustainability,
          value: this._value?.sustainability || 0
        });
      } else {
        data.push({
          id: task.attributes?.driversObj?.sustainability?.id,
          driver: DriverIds.Sustainability,
          value: this._value?.sustainability || 0
        });
      }

      return this._tasksEntity.updateOne(
        task.id,
        {
          data: {
            drivers: data
          }
        },
        {
          strapiPopulate: {
            populate: 'drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort'
          }
        },
        {
          type: 'snack'
        }
      )}
    )
  );

  constructor(
    private _tasksEntity: TasksEntity,
    private _driversEntity: DriversEntity,
    private _priorityScorePopService: PriorityScorePopService,
    private _store: Store<any>
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([ this._updateAction$ ]);

  }

  ngAfterViewInit() {
    this._priorityScorePopService.saveRef(this.popRef);
  }


  valueChange(e: any) {
    this._value = e;
  }
}
