import { Injectable } from '@angular/core';
import { Actions, createEffect} from '@ngrx/effects';
import { map, filter, switchMap } from 'rxjs/operators';
import { EffortEntryEntity } from './effort-entry-base.entity';
import { ZefAuthState, zefSelectAuthState } from '@zerops/zef/auth';
import { select, Store } from '@ngrx/store';
import { successOf } from '@zerops/zef/entities';
import { ZefSnackService } from '@zerops/zef/snack';

@Injectable()
export class EfforEntryBaseEffect {

  isAuthorized$ = this._store.pipe(
    select(zefSelectAuthState),
    filter((s) => s === ZefAuthState.Authorized)
  );

  private _onInit$ = createEffect(() => this.isAuthorized$.pipe(
    map(() => this._entity.searchAll({
      sort: this._entity.defaultApiSort
    }))
  ));

  private _onDeleteSuccessNotification$ = createEffect(() => this._actions$.pipe(
    successOf(this._entity.deleteOne),
    switchMap(() => this._snack.success$({ text: 'Record was successfully deleted' }))
  ), { dispatch: false });

  private _onAddSuccessNotification$ = createEffect(() => this._actions$.pipe(
    successOf(this._entity.addOne),
    switchMap(() => this._snack.success$({ text: 'Record was successfully added' }))
  ), { dispatch: false });

  private _onUpdateSuccessNotification$ = createEffect(() => this._actions$.pipe(
    successOf(this._entity.updateOne),
    switchMap(() => this._snack.success$({ text: 'Record was successfully updated' }))
  ), { dispatch: false });

  constructor(
    private _store: Store<any>,
    private _actions$: Actions<any>,
    private _entity: EffortEntryEntity,
    private _snack: ZefSnackService,
  ) { }

}
