import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState, zefDialogOpen } from '@zerops/zef/dialog';
import { SatPopover } from '@zerops/zef/popover';
import { TASK_DETAIL_DIALOG_KEY } from '@zerops/zemag/app';
import { TaskStatusEntity } from '@zerops/zemag/core/task-status-base';
import { TasksEntity, Task } from '@zerops/zemag/core/tasks-base';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './task-more-pop.constant';
import { TaskMorePopService } from './task-more-pop.service';

@Component({
  selector: 'zg-task-more-pop',
  templateUrl: './task-more-pop.feature.html',
  styleUrls: [ './task-more-pop.feature.scss' ]
})
export class TaskMorePopFeature extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams
  onUpdate$ = new Subject<{ id: number; data: any; }>();
  onDelete$ = new Subject<number>();
  onOpenDetail$ = new Subject<void>();

  // # Data
  // -- sync
  featureName = FEATURE_NAME;
  detailDialogKey = TASK_DETAIL_DIALOG_KEY;

  // -- angular
  @ViewChild(SatPopover, { static: true })
  popRef: SatPopover;

  // -- async
  taskStatuses$ = this._taskStatusEntity.list$();
  popState$ = this._store.pipe(
    select(selectZefDialogState(this.featureName))
  );
  id$ = this.popState$.pipe(
    map((d) => d.meta as number),
    distinctUntilChanged()
  );
  task$ = this.id$.pipe(
    switchMap((id) => id
      ? this._tasksEntity
        .entityById$(id)
        .pipe(filter((d) => !!d))
      : of({}) as Observable<Task>
    )
  );

  // # State resolver
  state = this.$connect({
    taskStatuses: this.taskStatuses$,
    popState: this.popState$,
    id: this.id$,
    task: this.task$
  });

  // # Action Streams
  private _updateAction$ = this.onUpdate$.pipe(
    map(({ id, data }) => this._tasksEntity.updateOne(
      id,
      { data },
      {
        strapiPopulate: {
          populate: 'drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort'
        }
      },
      {
        type: 'snack'
      }
    ))
  );
  private _deleteAction$ = this.onDelete$.pipe(
    map((id) => this._tasksEntity.deleteOne(
      id,
      {
        strapiPopulate: {
          populate: 'drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort'
        }
      },
      {
        type: 'snack'
      }
    ))
  );
  private _openDetailEditAction$ = this.onOpenDetail$.pipe(
    withLatestFrom(this.id$),
    map(([ _, id ]) => zefDialogOpen({
      key: this.detailDialogKey,
      meta: { id, edit: true }
    }))
  );

  constructor(
    private _tasksEntity: TasksEntity,
    private _taskStatusEntity: TaskStatusEntity,
    private _taskMorePopService: TaskMorePopService,
    private _store: Store<any>
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._updateAction$,
      this._deleteAction$,
      this._openDetailEditAction$
    ]);

  }

  ngAfterViewInit() {
    this._taskMorePopService.saveRef(this.popRef);
  }

}
