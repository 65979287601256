import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEntityKeys } from '@zerops/zemag/app';
import * as qs from 'qs';

@Injectable({ providedIn: 'root' })
export class StrapiTaskSearchService {
  private _apiPrefix = '/api';

  constructor(private _http: HttpClient) { }

  search(key: string) {
    const params = qs.stringify({
      filters: {
        $or: [
          {
            name: {
              $contains: key,
            }
          },
          {
            id: {
              $contains: key,
            }
          }
        ]
      },
      pagination: {
        start: 0,
        limit: 10,
      },
      populate: 'author,author.avatar.*,drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort',
    });

    return this._http.get(
      `${this._apiPrefix}/${ApiEntityKeys.Tasks}?${params}`
    );
  }

}
