import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TaskStatusBaseEffect } from './task-effort-base.effect';

@NgModule({
  imports: [ EffectsModule.forFeature([ TaskStatusBaseEffect ]) ]
})
export class TaskEffortBaseModule {

}
