import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { MatIconModule } from '@angular/material/icon';
import { TaskAddFeature } from './task-add.feature';
import { TaskAddFormModule } from './modules';
import { EffectsModule } from '@ngrx/effects';
import { TaskAddEffect } from './task-add.effect';

@NgModule({
  declarations: [ TaskAddFeature ],
  imports: [
    CommonModule,
    TaskAddFormModule,
    MatButtonModule,
    EffectsModule.forFeature([ TaskAddEffect ]),
    ZefProgressErrorModule,
    MatIconModule
  ],
  exports: [ TaskAddFeature ]
})
export class TaskAddModule {

}
