import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiWrapModule } from '@zerops/zui/wrap';
import { TaskDetailFeature } from './task-detail.feature';
import { TaskItemModule } from '@zerops/zemag/common/task-item';
import { MarkdownModule } from 'ngx-markdown';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { CodeFieldModule } from '@zerops/zui/code-field';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ZefFormFieldModule, ZefFormLabelModule, ZefFormModule } from '@zerops/zef/forms/modules';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { TaskDetailEffect } from './task-detail.effect';

@NgModule({
  declarations: [ TaskDetailFeature ],
  imports: [
    CommonModule,
    ZuiWrapModule,
    EffectsModule.forFeature([ TaskDetailEffect ]),
    FormsModule,
    TaskItemModule,
    MarkdownModule,
    ZuiSeparatorComponent,
    CodeFieldModule,
    DynamicPopAnchorModule,
    ZefDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ZefFormModule,
    ZefFormLabelModule,
    ZefFormFieldModule
  ],
  exports: [ TaskDetailFeature ]
})
export class TaskDetailModule {
}
