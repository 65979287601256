import { NgModule } from '@angular/core';
import { ZefEntitiesModule, CollectionManagerService } from '@zerops/zef/entities';
import { ZefErrorsModule } from '@zerops/zef/errors';
import { ZefProgressModule } from '@zerops/zef/progress';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZefNgrxRouterModule } from '@zerops/zef/ngrx-router';
import { META_CONFIG, ZefMetaModule } from '@zerops/zef/meta';
import { HttpStatusCodes } from '@zerops/zef/core';
import { ApiEntityKeys } from '@zerops/zemag/app';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZefAuthModule } from '@zerops/zef/auth';

@NgModule({
  imports: [
    ZefEntitiesModule.forRoot(),
    ZefErrorsModule.forRoot({
      // we don't want to show 401 error, as it always
      // triggers refresh, which either successes and
      // "nothing" visible happens for the user or fails
      // in which case single 403 error is added and handled
      // by a dialog error component
      blacklistedErrors: [ HttpStatusCodes.UNAUTHORIZED ],
      uniqueErrors: [ HttpStatusCodes.FORBIDDEN ]
    }),
    ZefAuthModule.forRoot({
      loginEndpoint: '/api/auth/local',
      tokenDataGetter: (res) => {
        const decoded = JSON.parse(atob(res.jwt.split('.')[1]));

        return {
          accessToken: res.jwt,
          userId: decoded.id,
          expirationDate: decoded.exp
        };
      }
    }),
    ZefProgressModule.forRoot(),
    ZefFormsModule.forRoot(),
    ZefNgrxRouterModule.forRoot(),
    ZefMetaModule.forRoot(),
    ZefDialogModule.forRoot()
  ],
  providers: [
    {
      provide: META_CONFIG,
      useValue: {
        baseTitle: 'Zero Management',
        separator: '~'
      }
    },
  ]
})
export class ZefLibModule {
  constructor(private _collectionManagerService: CollectionManagerService) {
    this._collectionManagerService.create({
      [ApiEntityKeys.Tasks]: { },
      [ApiEntityKeys.TaskStatus]: { },
      [ApiEntityKeys.TaskEffort]: { },
      [ApiEntityKeys.TaskCategory]: { },
      [ApiEntityKeys.TaskInternalPriority]: { },
      [ApiEntityKeys.Users]: { },
      [ApiEntityKeys.Drivers]: { },
      [ApiEntityKeys.EffortEntry]: { },
    });
  }
}
