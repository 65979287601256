<div class="__item">
  <div class="__item_content">

    <h6 class="__item_title">Marketing</h6>

    <div class="__item_values">
      <button
        *ngFor="let item of stars"
        type="button"
        class="__button"
        mat-icon-button
        [class.is-active]="_val.marketing === item"
        (click)="setVal('marketing', item)">
        {{ item }}
      </button>
    </div>

  </div>
</div>

<div class="__item">
  <div class="__item_content">

    <h6 class="__item_title">User experience</h6>

    <div class="__item_values">
      <button
        *ngFor="let item of stars"
        type="button"
        class="__button"
        [class.is-active]="_val.ux === item"
        mat-icon-button
        (click)="setVal('ux', item)">
        {{ item }}
      </button>
    </div>

  </div>
</div>

<div class="__item  is-last">
  <div class="__item_content">

    <h6 class="__item_title">Sustainability</h6>

    <div class="__item_values">
      <button
        *ngFor="let item of stars"
        type="button"
        class="__button"
        mat-icon-button
        [class.is-active]="_val.sustainability === item"
        (click)="setVal('sustainability', item)">
        {{ item }}
      </button>
    </div>

  </div>
</div>
