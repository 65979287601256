import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ZefFormsModule } from '@zerops/zef/forms';
import { DriversFormFieldComponent } from './drivers-form-field.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [ DriversFormFieldComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ZefFormsModule,
    MatButtonModule
  ],
  exports: [ DriversFormFieldComponent ]
})
export class DriversFormFieldModule { }
