import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { UsersBaseEffect } from './users-base.effect';

@NgModule({
  imports: [ EffectsModule.forFeature([ UsersBaseEffect ]) ]
})
export class UsersBaseModule {

}
