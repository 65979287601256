import { Component, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'zui-wrap',
  templateUrl: './wrap.component.html',
  styleUrls: [ './wrap.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WrapComponent {
  @Input()
  padding: 'medium' | 'large' | 'huge' = 'medium';

  @HostBinding('class.has-medium-padding')
  get classHasMediumPadding() {
    return this.padding === 'medium';
  }

  @HostBinding('class.has-large-padding')
  get classHasLargePadding() {
    return this.padding === 'large';
  }

  @HostBinding('class.has-huge-padding')
  get classHasHugePadding() {
    return this.padding === 'huge';
  }

}
