<div (paste)="onPaste($event)">
  <zg-task-add-form
    class="__form"
    (editorInited)="editorInit($event)"
    [categories]="state.taskCategories"
    [statuses]="state.taskStatuses"
    [internalPriorities]="state.taskInternalPriorities"
    [users]="state.users"
    [efforts]="state.taskEfforts"
    [state]="state.formState"
    (submit)="onAdd$.next()">
    <div class="u-text--center">
      <zef-progress-error full [key]="addKey" class="__progress">
        <button
          class="__button  c-button--full  c-button--larger"
          mat-flat-button
          type="submit"
          color="accent">
          Save task
        </button>
      </zef-progress-error>
    </div>
  </zg-task-add-form>
</div>
