import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component
} from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { zefDialogOpen } from '@zerops/zef/dialog';
import { TASK_DETAIL_DIALOG_KEY } from '@zerops/zemag/app';
import { StrapiTaskSearchService } from '@zerops/zemag/core/strapi';
import { transformTaskItem } from '@zerops/zemag/core/tasks-base';
import { AssigneesPopService } from '@zerops/zemag/features/assignees-pop';
import { CategoryPopService } from '@zerops/zemag/features/category-pop';
import { EffortPopService } from '@zerops/zemag/features/effort-pop/effort-pop.service';
import { InternalPriorityPopService } from '@zerops/zemag/features/internal-priority-pop';
import { PriorityScorePopService } from '@zerops/zemag/features/priority-score-pop';
import { StatusPopService } from '@zerops/zemag/features/status-pop';
import { TaskMorePopService } from '@zerops/zemag/features/task-more-pop';
import hotkeys from 'hotkeys-js';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'zg-search',
  templateUrl: './search.feature.html',
  styleUrls: [ './search.feature.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFeature extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams
  searchval$ = new Subject<string>();
  onOpenDetail$ = new Subject<number>();

  // # Data
  // -- sync
  statusPopRef = this._statusPopService.getRef();
  effortPopRef = this._effortPopService.getRef();
  categoryPopRef = this._categoryPopService.getRef();
  internalPriorityPopRef = this._internalPriorityPopService.getRef();
  priorityScorePopRef = this._priorityScorePopService.getRef();
  assigneesPopRef = this._assigneesPopService.getRef();
  taskMorePopRef = this._taskMorePopService.getRef();
  detailDialogKey = TASK_DETAIL_DIALOG_KEY;
  dialogOpen = false;

  // -- async
  data$ = this.searchval$.pipe(
    debounceTime(100),
    switchMap((val) => {
      if (!val) { return of([]); }

      return this._taskSearchApi
        .search(val)
        .pipe(
          map((res: any) => res.data.map(transformTaskItem)),
          catchError((err) => {
            console.warn(err);
            return of([]);
          })
        );
    })
  );


  // # State resolver
  state = this.$connect({
    data: this.data$
  });

  // # Action Streams
  private _openDetailAction$ = this.onOpenDetail$.pipe(
    map((id) => zefDialogOpen({
      key: this.detailDialogKey,
      meta: { id }
    }))
  );

  constructor(
    private _taskSearchApi: StrapiTaskSearchService,
    private _statusPopService: StatusPopService,
    private _effortPopService: EffortPopService,
    private _categoryPopService: CategoryPopService,
    private _internalPriorityPopService: InternalPriorityPopService,
    private _priorityScorePopService: PriorityScorePopService,
    private _assigneesPopService: AssigneesPopService,
    private _taskMorePopService: TaskMorePopService
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._openDetailAction$
    ]);

  }

  ngAfterViewInit() {
    if (!this.dialogOpen) {
      hotkeys('alt+f', () => {
        this.dialogOpen = true;
        this.renderScheduler.schedule();
      });
    }
  }

}
