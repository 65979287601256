import { Component, Input } from '@angular/core';
import { User } from '@zerops/zemag/core/users-base';

@Component({
  selector: 'zg-logo',
  templateUrl: './logo.component.html',
  styleUrls: [ './logo.component.scss' ]
})
export class LogoComponent {

  @Input()
  user: User;

}
