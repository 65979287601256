import { Routes } from '@angular/router';
import { createRule, zefPermissionGuardActivation } from '@zerops/zef/permission';
import { DEFAULT_APP_ROUTE } from './app.constant';
import { Roles } from './app.permissions';

const authorizedRole = (redirect = '/login') => ({
  zefPermissions: createRule(
    { only: [ Roles.Authorized ] },
    [ redirect ]
  )
});

const unauthorizedRole = (redirect = '/dashboard') => ({
  zefPermissions: createRule(
    { only: [ Roles.Unauthorized ] },
    [ redirect ]
  )
});

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: DEFAULT_APP_ROUTE.join('/'),
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../pages/+dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [ zefPermissionGuardActivation ],
    data: authorizedRole()
  },
  {
    path: 'user',
    loadChildren: () => import('../pages/+user-detail/user-detail.module').then(m => m.UserDetailModule),
    canActivate: [ zefPermissionGuardActivation ],
    data: authorizedRole()
  },
  {
    path: 'task',
    loadChildren: () => import('../pages/+task-detail/task-detail.module').then(m => m.TaskDetailPageModule),
    canActivate: [ zefPermissionGuardActivation ],
    data: authorizedRole()
  },
  {
    path: 'export',
    loadChildren: () => import('../pages/+export/export.module').then(m => m.ExportModule),
    canActivate: [ zefPermissionGuardActivation ],
    data: authorizedRole()
  },
  {
    path: 'login',
    loadChildren: () => import('../pages/+login/login.module').then(m => m.LoginModule),
    canActivate: [ zefPermissionGuardActivation ],
    data: unauthorizedRole()
  }
];
