import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StrapiUploadService {
  private _apiPrefix = '/api';

  constructor(private _http: HttpClient) { }

  upload(file: Blob) {
    const formData = new FormData();

    formData.append('files', file);

    return this._http.post(
      `${this._apiPrefix}/upload`,
      formData,
      // { observe: 'events', reportProgress: true }
    );
  }

}
