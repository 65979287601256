<div
  class="__wrap  c-task-item_wrap"
  [style.backgroundColor]="internalPriority.bg_color"
  [ngClass]="'is-' + internalPriority.slug">

  <div class="__top-wrap">

    <button
      mat-icon-button
      #taskMoreButtonRef="dynamicPopAnchor"
      (click)="taskMoreButtonRef.anchor();quickEditOpen.emit();"
      [zuiDynamicPopAnchor]="taskMorePopRef"
      [zefNgrxDialogOpen]="taskMorePopKey"
      [zefNgrxDialogOpenMeta]="id"
      type="button"
      class="__more">
      <mat-icon>more_vert</mat-icon>
    </button>

    <div class="__top">

      <!-- category -->
      <div>
        <button
          mat-flat-button
          #categoryButtonRef="dynamicPopAnchor"
          (click)="categoryButtonRef.anchor();quickEditOpen.emit();"
          [zuiDynamicPopAnchor]="categoryPopRef"
          [zefNgrxDialogOpen]="categoryPopKey"
          [zefNgrxDialogOpenMeta]="id"
          type="button"
          class="c-button--smaller  __category"
          [ngClass]="'is-' + category.slug">
          {{ category.name }}
        </button>

        <a
          mat-button
          *ngIf="!!discussionLink"
          class="__discussion-link  c-button--smaller"
          [href]="discussionLink"
          [target]="'_blank'">
          <mat-icon fontSet="material-icons-outlined">chat</mat-icon>
        </a>

      </div>

      <!-- status -->
      <div class="u-text--right">
        <button
          #statusButtonRef="dynamicPopAnchor"
          (click)="statusButtonRef.anchor();quickEditOpen.emit();"
          [zuiDynamicPopAnchor]="statusPopRef"
          [zefNgrxDialogOpen]="statusPopKey"
          [zefNgrxDialogOpenMeta]="id"
          mat-stroked-button
          type="button"
          class="c-button--smaller">
          {{ status.name }}
        </button>

      </div>

    </div>

  </div>

  <h5 class="__name  c-task-item_name">
    <button (click)="copy('z'+ id, copyButtonRef)" #copyButtonRef mat-button class="__copy-to-clipboard" type="button">#z{{ id }}</button> <a
      [routerLink]="[ '/task', id ]">
      <span (click)="nameClicked.emit(id); $event.preventDefault(); $event.stopPropagation();">{{ name }}</span>&nbsp;<span *ngIf="!!author"> ~ <img class="__author-avatar" [title]="author.username" [src]="author?.avatar.data.attributes.formats.thumbnail.url" /></span>
    </a>
  </h5>

  <div class="__info">

    <div>

      <!-- internal priority -->
      <div class="__info_item  __internal-priority">
        <ng-container *ngIf="!!internalPriority">
          <button
            type="button"
            mat-flat-button
            #internalPriorityButtonRef="dynamicPopAnchor"
            (click)="internalPriorityButtonRef.anchor();quickEditOpen.emit();"
            [zuiDynamicPopAnchor]="internalPriorityPopRef"
            [zefNgrxDialogOpen]="internalPriorityPopKey"
            [zefNgrxDialogOpenMeta]="id"
            class="c-button--smaller   __info_button  c-button--full">
            <span [style.backgroundColor]="internalPriority.status_color" class="__internal-priority_status" [ngClass]="'is-' + internalPriority.slug"></span>
            <span class="__internal-priority_name">{{ internalPriority.name }}</span>
          </button>
        </ng-container>
      </div>

      <!-- priority score -->
      <div class="__info_item  __priority-score">

        <button
          mat-flat-button
          type="button"
          #priorityScoreButtonRef="dynamicPopAnchor"
          (click)="priorityScoreButtonRef.anchor(); quickEditOpen.emit();"
          [zuiDynamicPopAnchor]="priorityScorePopRef"
          [zefNgrxDialogOpen]="priorityScorePopKey"
          [zefNgrxDialogOpenMeta]="id"
          class="__priority">

          <mat-progress-spinner
            class="__priority_progress  is-bg"
            [mode]="'determinate'"
            [diameter]="30"
            [value]="100">
          </mat-progress-spinner>

          <mat-progress-spinner
            class="__priority_progress"
            [mode]="'determinate'"
            [color]="'accent'"
            [diameter]="30"
            [value]="priorityScore">
          </mat-progress-spinner>

          <strong class="__priority_value">{{ priorityScore }}</strong>

        </button>

      </div>

    </div>

    <div class="u-text--right">

      <!-- effort -->
      <div class="__info_item">
        <button
          mat-flat-button
          #effortButtonRef="dynamicPopAnchor"
          (click)="effortButtonRef.anchor();quickEditOpen.emit();"
          [zuiDynamicPopAnchor]="effortPopRef"
          [zefNgrxDialogOpen]="effortPopKey"
          [zefNgrxDialogOpenMeta]="id"
          [class.is-empty]="!effort?.name"
          class="[ __info_button ]  [ __effort-button ]  [ c-button--smaller   c-button--full ]">
          <mat-icon>schedule</mat-icon>&nbsp;<span>{{ effort?.name || 'Set effort' }}</span>
        </button>
      </div>

      <!-- assignees -->
      <div class="__assignee  __info_item">
        <button
          mat-flat-button
          #assigneeButtonRef="dynamicPopAnchor"
          (click)="assigneeButtonRef.open(); quickEditOpen.emit();"
          [zuiDynamicPopAnchor]="assigneesPopRef"
          [zefNgrxDialogOpen]="assigneesPopKey"
          [zefNgrxDialogOpenMeta]="id"
          [class.is-empty]="!assignees?.length"
          class="[ __assignee-button ]  [ __info_button ]  [ c-button--smaller ]">

          <ng-container *ngIf="!!limitedAssignees?.length">
            <img
              *ngFor="let assignee of limitedAssignees; let first = first; trackBy: trackById"
              class="__assignee_avatar"
              [class.is-done]="assignee.done"
              [class.is-first]="first"
              [src]="assignee.user?.data?.attributes?.avatar.data?.attributes.url"
              [alt]="assignee.user?.data?.attributes?.username"
            />
          </ng-container>

          <ng-container *ngIf="!limitedAssignees?.length">
            <mat-icon>person_add_alt</mat-icon>
          </ng-container>

        </button>
      </div>

    </div>

  </div>

  <!-- <div>
    drivers:
    ux: {{ drivers?.ux?.value }} <br/>
    marketing: {{ drivers?.marketing?.value }} <br/>
    difficulty: {{ drivers?.difficulty?.value }}
  </div> -->

</div>
