<zef-dialog
  [open]="dialogOpen"
  (closeTrigger)="dialogOpen = false">
  <mat-card class="__card" *ngIf="dialogOpen">

    <!-- <button
      mat-icon-button
      tabindex="-1"
      (click)="dialogOpen = false"
      type="button"
      class="__close-button">
      <mat-icon>close</mat-icon>
    </button> -->

    <zef-form class="__form">
      <zef-form-field last>
        <mat-form-field class="__form-field" color="accent">

          <mat-label>Search</mat-label>

          <input
            matInput
            autocomplete="off"
            #inputRef
            (keyup)="searchval$.next(inputRef.value)"
            type="text"
            name="name"
            [placeholder]="'Search tasks by id, title..'"
          />

        </mat-form-field>
      </zef-form-field>
    </zef-form>

    <ng-container *ngIf="state.data?.length && inputRef.value">
      <div class="__item-wrap" *ngFor="let item of state.data; trackBy: trackByIndex">
        <zg-task-item
          (nameClicked)="onOpenDetail$.next($event)"
          [statusPopRef]="statusPopRef"
          [effortPopRef]="effortPopRef"
          [categoryPopRef]="categoryPopRef"
          [assigneesPopRef]="assigneesPopRef"
          [priorityScorePopRef]="priorityScorePopRef"
          [taskMorePopRef]="taskMorePopRef"
          [internalPriorityPopRef]="internalPriorityPopRef"
          [discussionLink]="item.attributes?.discussion_link"
          [name]="item.attributes.name"
          [assignees]="item.attributes.assignees"
          [desc]="item.attributes.desc"
          [id]="item.id"
          [effort]="item.attributes?.task_effort?.data?.attributes"
          [internalPriority]="item.attributes.internal_priority?.data.attributes"
          [status]="item.attributes.status?.data.attributes"
          [author]="item.attributes.author?.data?.attributes"
          [drivers]="item.attributes.driversObj"
          [category]="item.attributes.task_category?.data.attributes"
          [priorityScore]="item.attributes.priorityScore">
      </zg-task-item>

      </div>
    </ng-container>

  </mat-card>
</zef-dialog>
