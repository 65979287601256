import { HttpClient, HttpParams } from '@angular/common/http';
import { DataService, Update, QueryParams } from '@zerops/zef/entities';
import isArray from 'lodash-es/isArray';
import { map } from 'rxjs/operators';

export class StrapiDataService<T> implements DataService<T> {
  protected _name: string;
  private _apiPrefix = '/api';

  get name() {
    return this._name;
  }

  constructor(
    public entityName: string,
    private _http: HttpClient
  ) {
    this._name = `${entityName} StrapiDataService`;
    this.entityName = entityName;
  }

  add(entity: T, meta: any): any {
    const params = this._getParams(meta.strapiPopulate);

    return this._http
      .post<T>(
        `${this._apiPrefix}/${this.entityName}`,
        entity,
        { params }
      )
      .pipe(
        map((res: any) => ({
          items: [res.data]
        }))
      );
  }

  delete(id: string) {
    return this._http.delete<string>(`${this._apiPrefix}/${this.entityName}/${id}`);
  }

  // TODO: interface
  getAll(data: any = {}) {
    return this._http.post<T[]>(
      `${this._apiPrefix}/${this.entityName}/list`,
      // TODO: temporary -> REMOVE
      {
        search: [{ ...data.search[0], type: 'match' }],
        sort: []
      }
    );
  }

  // TODO: interface
  searchAll(queryParams: QueryParams | string) {
    const qParams = typeof queryParams === 'string'
      ? { fromString: queryParams }
      : { fromObject: queryParams };

    const params = new HttpParams(qParams);

    return this._http.get<{ data: T[]; meta: any; }>(
      `${this._apiPrefix}/${this.entityName}`,
      { params }
    ).pipe(map((res) => ({
      items: isArray(res) ? res : res.data,
      limit: qParams.fromObject?._limit || 1000,
      offset: qParams.fromObject?._start || 0,
      totalHits: res?.meta?.total || 0
    })));
  }

  getById(id: string, meta: any): any {
    const params = this._getParams(meta.strapiPopulate);

    return this._http.get<T>(
      `${this._apiPrefix}/${this.entityName}/${id}`,
      {
        params
      }
    ).pipe(
      map((res: any) => ({
        items: [res.data]
      }))
    );
  }

  getWithQuery(queryParams: QueryParams | string) {
    const params = this._getParams(queryParams);

    return this._http.get<T[]>(`${this._apiPrefix}/${this.entityName}`, { params });
  }

  update(update: Update<T>, meta: any): any {

    const params = this._getParams(meta.strapiPopulate);

    return this._http
      .put<T>(
        `${this._apiPrefix}/${this.entityName}/${update.id}`,
        update.changes,
        { params }
      )
      .pipe(
        map((res: any) => ({
          items: [res.data]
        }))
      );
  }

  restore(id: string) {
    return this._http.put<T>(`${this._apiPrefix}/${this.entityName}/${id}/restore`, {});
  }

  private _getParams(queryParams: QueryParams | string) {
    if (!queryParams) {
      return {};
    }

    const qParams = typeof queryParams === 'string'
      ? { fromString: queryParams }
      : { fromObject: queryParams };
    const params = new HttpParams(qParams);

    return params;
  }
}
