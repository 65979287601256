import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zemag/app';
import { FormState, validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { FEATURE_NAME } from './effort-entry-add-edit-pop.constant';

export interface EffortEntryAddEditPopFormValue {
  createdAt: string;
  effort: number;
}

export type EffortEntryAddEditPopFormState = FormState<EffortEntryAddEditPopFormValue>;

@Injectable({ providedIn: 'root' })
export class EffortEntryAddEditPopForm extends ZefForm<EffortEntryAddEditPopFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        createdAt: undefined,
        effort: undefined
      },
      {
        createdAt: validate(required),
        effort: validate(required)
      }
    );
  }
}
