import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffortPopFeature } from './effort-pop.feature';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { PopStateModule } from '@zerops/zui/pop-state';

@NgModule({
  declarations: [ EffortPopFeature ],
  imports: [
    CommonModule,
    SatPopoverModule,
    MatCardModule,
    MatListModule,
    ActionListItemModule,
    PopStateModule
  ],
  exports: [ EffortPopFeature ]
})
export class EffortPopModule {

}
