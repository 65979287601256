import { Pipe, PipeTransform } from '@angular/core';
import get from 'lodash-es/get';

@Pipe({
  pure: true,
  name: 'nestedPath'
})
export class NestedPathPipe implements PipeTransform {
  transform(value: any, path: string) {
    if (!value) {
      return undefined;
    }

    if (path.indexOf('.') === -1) {
      return value[path];
    }

    return get(value, path);
  }
}
