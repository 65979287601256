import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TaskInternalPriorityBaseEffect } from './task-internal-priority-base.effect';

@NgModule({
  imports: [ EffectsModule.forFeature([ TaskInternalPriorityBaseEffect ]) ]
})
export class TaskInternalPriorityBaseModule {

}
