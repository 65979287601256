import {
  ActionReducerMap,
  MetaReducer,
  ActionReducer
} from '@ngrx/store';
import { storeLogger } from '@zerops/ngrx-store-logger';
import { environment } from '@zerops/zemag/env';
import { AppState } from '@zerops/zemag/app';

export function logger(reducer: ActionReducer<AppState>): any {
  return storeLogger({
    collapsed: true,
    filter: {
      blacklist: [ '@ngrx/store/update-reducers' ]
    }
  })(reducer);
}

export const appReducers: ActionReducerMap<AppState> = {};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [ logger ]
  : [];
