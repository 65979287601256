import { Injectable } from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';

@Injectable({ providedIn: 'root' })
export class InternalPriorityPopService {
  private _ref: SatPopover;

  saveRef(ref: SatPopover) {
    this._ref = ref;
  }

  getRef() {
    return this._ref;
  }
}
