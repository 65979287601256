import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { zefSelectAuthData } from '@zerops/zef/auth';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { formValueOnValid } from '@zerops/zef/forms';
import { SatPopover } from '@zerops/zef/popover';
import { EffortEntryEntity } from '@zerops/zemag/core/effort-entry-base';
import { NgrxValueConverter, NgrxValueConverters } from 'ngrx-forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './effort-entry-add-edit-pop.constant';
import { EffortEntryAddEditPopForm } from './effort-entry-add-edit-pop.form';
import { EffortEntryAddEditPopService } from './effort-entry-add-edit-pop.service';

@Component({
  selector: 'zg-effort-entry-add-edit-pop',
  templateUrl: './effort-entry-add-edit-pop.feature.html',
  styleUrls: [ './effort-entry-add-edit-pop.feature.scss' ]
})
export class EffortEntryAddEditPopFeature extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams
  onAdd$ = new Subject<void>();
  onUpdate$ = new Subject<void>();

  // # Data
  // -- sync
  featureName = FEATURE_NAME;
  addKey = this._effortEntryEntity.addOne.type;
  updateKey = this._effortEntryEntity.updateOne.type;
  maxDate = new Date();

  // -- angular
  @ViewChild(SatPopover, { static: true })
  popRef: SatPopover;

  // -- async
  formState$ = this._effortEntryAddEditPopForm.state$;
  popState$ = this._store.pipe(
    select(selectZefDialogState(this.featureName))
  );
  taskId$ = this.popState$.pipe(
    map((d) => d.meta?.taskId as string),
    distinctUntilChanged()
  );
  effortEntryId$ = this.popState$.pipe(
    map((d) => d.meta?.effortEntryId as string),
    distinctUntilChanged()
  );
  mode$ = this.popState$.pipe(
    map((d) => d.meta?.mode as 'add' | 'edit'),
    distinctUntilChanged()
  );

  // # State resolver
  state = this.$connect({
    formState: this.formState$,
    mode: this.mode$
  });

  dateValueConverter: NgrxValueConverter<Date | null, string | null> = {
    convertViewToStateValue(value) {
      if (value === null) {
        return null;
      }
      value = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
      return NgrxValueConverters.dateToISOString.convertViewToStateValue(value);
    },
    convertStateToViewValue: NgrxValueConverters.dateToISOString.convertStateToViewValue,
  };

  // # Action Streams
  private _addAction$ = this.onAdd$.pipe(
    formValueOnValid(this._effortEntryAddEditPopForm),
    withLatestFrom(
      this.taskId$,
      this._store.pipe(
        select(zefSelectAuthData),
        filter((d) => !!d),
        map((d) => d.userId as number)
      )
    ),
    map(([ values, taskId, userId ]) => this._effortEntryEntity.addOne({
      data: {
        created: values.createdAt,
        hour_effort: values.effort,
        task_id: parseInt(taskId),
        user_id: userId
      }
    }))
  );
  private _updateAction$ = this.onUpdate$.pipe(
    formValueOnValid(this._effortEntryAddEditPopForm),
    withLatestFrom(
      this.effortEntryId$,
      this.taskId$,
      this._store.pipe(
        select(zefSelectAuthData),
        filter((d) => !!d),
        map((d) => d.userId as number)
      )
    ),
    map(([ values, effortEntryId, taskId, userId ]) => this._effortEntryEntity.updateOne(
      effortEntryId,
      {
        data: {
          created: values.createdAt,
          hour_effort: values.effort,
          task_id: parseInt(taskId),
          user_id: userId
        }
      },
      {},
      {
        type: 'snack'
      }
    ))
  );

  constructor(
    private _store: Store<any>,
    private _effortEntryAddEditPopService: EffortEntryAddEditPopService,
    private _effortEntryAddEditPopForm: EffortEntryAddEditPopForm,
    private _effortEntryEntity: EffortEntryEntity
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._addAction$,
      this._updateAction$
    ]);
  }

  ngAfterViewInit() {
    this._effortEntryAddEditPopService.saveRef(this.popRef);
  }

}
