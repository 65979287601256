import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'zg-drivers-form-field',
  templateUrl: './drivers-form-field.component.html',
  styleUrls: ['./drivers-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DriversFormFieldComponent),
      multi: true
    }
  ]
})
export class DriversFormFieldComponent implements ControlValueAccessor {

  _val: any;
  stars = [0, 1, 2, 3, 4, 5];

  @Input()
  set value(v: any) {
    if (v !== undefined && this._val !== v){
      this._val = v;
      this._propagateChange(v);
    }
  }

  @Output()
  valueChange = new EventEmitter<any>();

  writeValue(v: any) {
    this.value = v;
    this.valueChange.emit(v);
  }

  registerOnChange(fn: any) {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this._propagateTouch = fn;
  }

  private _propagateChange = (_: any) => {
    return;
  };

  private _propagateTouch = (_: any) => {
    return;
  };

  setVal(key: string, value: number) {
    const val = {
      ...this._val,
      [key]: value
    };

    this.writeValue(val);
  }
}
