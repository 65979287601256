import { ApplicationConfig, importProvidersFrom, isDevMode, LOCALE_ID, Provider, SecurityContext } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { translocoConfig, provideTransloco } from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { MonacoEditorModule } from  'ngx-monaco-editor-v2';
import { MarkdownModule } from 'ngx-markdown';

import { monacoConfig } from '@zerops/zui/code-field';
import { environment } from '@zerops/zemag/env';
import { CoreModule } from '@zerops/zemag/core';
import { ZefLibModule } from '../core/zef-lib';
import { appRoutes } from './app.routes';
import { API_ENDPOINT } from './app.constant';
import { appReducers, metaReducers } from './app.reducer';
import { AppEffect } from './app.effect';
import { apiBaseInterceptor } from '../interceptors';

export const envProviders: Array<Provider> = !environment.production
  ? [
      {
        provide: API_ENDPOINT,
        useValue: environment.apiUrl
      }
    ]
  : [];

export const appConfig: ApplicationConfig = {
    providers: [
      provideAnimations(),
      provideRouter(
        appRoutes,
        withInMemoryScrolling({
          scrollPositionRestoration: 'top'
        })
      ),
      provideHttpClient(
        withInterceptors([apiBaseInterceptor])
      ),
      importProvidersFrom(
        StoreModule.forRoot(appReducers, { metaReducers }),
        EffectsModule.forRoot(),
        EffectsModule.forFeature([ AppEffect ]),
        MonacoEditorModule.forRoot(monacoConfig),
        MarkdownModule.forRoot({
          sanitize: SecurityContext.NONE
        }),
        CoreModule,
        ZefLibModule
      ),
      provideTransloco({
        config: translocoConfig({
          availableLangs: ['en'],
          defaultLang: 'en',
          prodMode: !isDevMode()
        })
      }),
      provideTranslocoMessageformat({
        locales: 'en-US'
      }),
      provideTranslocoLocale({
        langToLocaleMapping: {
          en: 'en-US'
        }
      }),
      {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
            appearance: 'outline',
            floatLabel: 'always'
        }
      },
      {
          provide: LOCALE_ID,
          useValue: 'cs'
      },
      ...envProviders
    ]
  };
