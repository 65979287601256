<div class="__app-grid" [class.is-authorized]="state.isAuthorized">

  <div class="__menu" *ngIf="state.isAuthorized">

    <div class="__menu-info-wrap">

      <div class="__menu-info-wrap_content">
        <button
          #menuTriggerRef
          (click)="userMenuPopRef.open()"
          type="button"
          class="__user-trigger">
          <zg-logo [user]="state.authorizedUser"></zg-logo>
        </button>
      </div>

      <sat-popover
        #userMenuPopRef
        [autoFocus]="false"
        [anchor]="menuTriggerRef"
        [restoreFocus]="false"
        [verticalAlign]="'below'"
        [horizontalAlign]="'center'"
        hasBackdrop
        (backdropClicked)="userMenuPopRef.close()">
        <mat-card class="__menu-pop-card  c-soft-elevation">
          <mat-action-list>

            <button
              (click)="userMenuPopRef.close();"
              [routerLink]="[ '/user', state.authorizedUser?.id ]"
              mat-list-item>
              <zui-action-list-item [icon]="'person'" [label]="'Profile'">
              </zui-action-list-item>
            </button>

            <button
              (click)="onLogout$.next();userMenuPopRef.close();"
              mat-list-item>
              <zui-action-list-item [icon]="'logout'" [label]="'Logout'">
              </zui-action-list-item>
            </button>

          </mat-action-list>
        </mat-card>
      </sat-popover>

    </div>

    <div class="__menu-scroll">

      <!-- view -->
      <div class="__menu-section">
        <div class="__filter">

          <span class="__filter_label">
            <strong>Zemag Views</strong>
          </span>

          <div class="__filter_content">

            <a
              mat-button
              [routerLink]="[ '/dashboard', views.Team, (state.cleanRouterParams) ]"
              [class.is-active]="state.showAs === views.Team"
              class="__filter_button  has-fixed-width"
              type="button">
              <mat-icon>people_alt</mat-icon> Team
            </a>

            <div class="__sub-button-wrap">
              <mat-icon class="__sub-button_icon">subdirectory_arrow_right</mat-icon>
              <a
                mat-button
                [routerLink]="[ '/dashboard', views.Team, { activeStatuses: 'todo,ongoing,doing,in-review,devel' } ]"
                [class.is-active]="state.showAs === views.Team && state.routerParams?.activeStatuses === 'todo,ongoing,doing,in-review,devel'"
                class="c-button--smaller  __filter_button  __filter_button--minor"
                type="button">
                <mat-icon>whatshot</mat-icon> Standup
              </a>
            </div>

            <a
              mat-button
              [routerLink]="[ '/dashboard', views.Kanban, (state.cleanRouterParams) ]"
              [class.is-active]="state.showAs === views.Kanban"
              class="__filter_button  has-fixed-width"
              type="button">
              <mat-icon>view_kanban</mat-icon> Kanban
            </a>

            <a
              mat-button
              [routerLink]="[ '/dashboard', views.Categories, (state.cleanRouterParams) ]"
              [class.is-active]="state.showAs === views.Categories"
              class="__filter_button  has-fixed-width"
              type="button">
              <mat-icon>category</mat-icon> Categories
            </a>

            <div class="__sub-button-wrap">
              <mat-icon class="__sub-button_icon">subdirectory_arrow_right</mat-icon>
              <a
                mat-button
                [routerLink]="[ '/dashboard', views.Categories, { activeStatuses: 'backlog' } ]"
                [class.is-active]="state.showAs === views.Categories && state.routerParams?.activeStatuses === 'backlog'"
                class="c-button--smaller  __filter_button  __filter_button--minor"
                type="button">
                <mat-icon fontSet="material-icons-outlined">next_week</mat-icon> Backlog
              </a>
            </div>


            <div class="__user-select-wrap">

              <a
                [routerLink]="[ '/user', item.id ]"
                [routerLinkActive]="'is-active'"
                class="__user-filter-button"
                *ngFor="let item of state.users; trackBy: trackByIndex;">
                <img
                  class="__user-filter-button_image"
                  [src]="item.avatar?.url"
                  [alt]="item.username"
                />
              </a>

            </div>

          </div>
        </div>
      </div>

      <ng-container *ngIf="state.hasFilters">

        <zui-separator [orientation]="'horizontal'" [spacing]="'medium'"></zui-separator>

        <!-- internal priority -->
        <div class="__menu-section">
          <div class="__filter">

            <span class="__filter_label">
              Internal priority
            </span>

            <div class="__filter_content">
              <a
                *ngFor="let item of state.taskInternalPriorities; trackBy: trackByIndex;"
                mat-button
                [routerLink]="state.activeInternalPriorities?.includes(item.attributes?.slug)
                  ? [ state.routerUrl, _paramsWithout(state.cleanRouterParams, { internalPriorities: item.attributes?.slug })]
                  : [ state.routerUrl, _paramsWith(state.cleanRouterParams, { internalPriorities: item.attributes?.slug })]"
                [class.is-active]="state.activeInternalPriorities?.includes(item.attributes?.slug)"
                class="__filter_tag  c-button--smaller"
                type="button">

                <span
                  [style.backgroundColor]="item.attributes?.status_color"
                  class="__filter_tag-status"
                  [ngClass]="'is-' + item.attributes?.slug">
                </span>

                {{ item.attributes.name }}

              </a>
            </div>
          </div>
        </div>

        <ng-container *ngIf="state.showAs !== views.Kanban">
          <zui-separator [size]="'small'" [spacing]="'small'"></zui-separator>

          <!-- status -->
          <div class="__menu-section">
            <div class="__filter">

              <span class="__filter_label">
                Status
              </span>

              <div class="__filter_content">
                <a
                  *ngFor="let item of state.taskStatuses; trackBy: trackByIndex;"
                  mat-button
                  [routerLink]="state.activeStatuses?.includes(item.attributes?.slug)
                    ? [ state.routerUrl, _paramsWithout(state.cleanRouterParams, { activeStatuses: item.attributes?.slug })]
                    : [ state.routerUrl, _paramsWith(state.cleanRouterParams, { activeStatuses: item.attributes?.slug })]"
                  [class.is-active]="state.activeStatuses?.includes(item.attributes?.slug)"
                  class="__filter_tag  c-button--smaller"
                  type="button">
                  {{ item.attributes.name }}
                </a>
              </div>

            </div>
          </div>

        </ng-container>

        <ng-container *ngIf="state.showAs !== views.Categories">

          <zui-separator [size]="'small'" [spacing]="'small'"></zui-separator>

          <!-- categories -->
          <div class="__menu-section">
            <div class="__filter">

              <span class="__filter_label">
                Category
              </span>

              <div class="__filter_content">
                <a
                  *ngFor="let item of state.taskCategories; trackBy: trackByIndex;"
                  mat-button
                  [routerLink]="state.activeCategories?.includes(item.attributes?.slug)
                    ? [ state.routerUrl, _paramsWithout(state.cleanRouterParams, { activeCategories: item.attributes?.slug })]
                    : [ state.routerUrl, _paramsWith(state.cleanRouterParams, { activeCategories: item.attributes?.slug })]"
                  [class.is-active]="state.activeCategories?.includes(item.attributes?.slug)"
                  class="__filter_tag  c-button--smaller"
                  type="button">
                  {{ item.attributes.name }}
                </a>
              </div>

            </div>
          </div>

        </ng-container>

      </ng-container>

      <div class="__add-task-wrap">
        <button
          mat-raised-button
          color="accent"
          [zefNgrxDialogOpen]="addDialogKey"
          class="c-button--full">
          <mat-icon>add</mat-icon> Add task
        </button>
      </div>
    </div>

  </div>

  <div class="__content">
    <router-outlet></router-outlet>
  </div>

</div>

<zef-dialog
  [backdropClass]="'cdk-overlay-light-dark-backdrop'"
  [paneClass]="'zg-task-dialog-backdrop'"
  [open]="state.addDialogOpen"
  [autoFocus]="false"
  [options]="{
    autoFocus: false,
    closeOnNavigation: false,
    maxHeight: '95vh',
    maxWidth: '80vw',
    minWidth: 0,
    width: '1200px'
  }">
  <div class="__dialog-scroll-wrap">
    <zef-scrollbar maxHeight="95vh">
      <div class="u-position--relative  __add-dialog-wrap" *ngIf="state.isAuthorized">

        <button
          class="__close-button"
          type="button"
          [zefNgrxDialogClose]="addDialogKey"
          mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>

        <zg-task-add />

      </div>
    </zef-scrollbar>
  </div>
</zef-dialog>

<zef-dialog
  [backdropClass]="'cdk-overlay-light-dark-backdrop'"
  [paneClass]="'zg-task-dialog-backdrop'"
  [open]="state.detailDialogOpen"
  [autoFocus]="false"
  (closeTrigger)="onCloseDialog$.next()"
  [options]="{
    autoFocus: false,
    closeOnNavigation: false,
    height: '95vh',
    maxWidth: '80vw',
    minWidth: 0,
    width: '1200px'
  }">
  <div class="__dialog-scroll-wrap">
    <zef-scrollbar maxHeight="95vh">
      <div class="u-position--relative  __detail-dialog-wrap" *ngIf="!!state.detailDialogMeta?.id && state.isAuthorized">

        <button
          class="__close-button"
          type="button"
          [zefNgrxDialogClose]="detailDialogKey"
          mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>

        <zg-task-detail [edit]="state.detailDialogMeta?.edit" [id]="state.detailDialogMeta?.id"></zg-task-detail>

      </div>
    </zef-scrollbar>
  </div>
</zef-dialog>

<zg-status-pop></zg-status-pop>

<zg-effort-pop></zg-effort-pop>

<zg-effort-entries-pop></zg-effort-entries-pop>

<zg-effort-entry-add-edit-pop></zg-effort-entry-add-edit-pop>

<zg-category-pop></zg-category-pop>

<zg-internal-priority-pop></zg-internal-priority-pop>

<zg-priority-score-pop></zg-priority-score-pop>

<zg-assignees-pop></zg-assignees-pop>

<zg-task-more-pop></zg-task-more-pop>

<zg-search></zg-search>

<zui-snack-error
  *zefSnackErrorOutlet="let data = data; let instance = instance;"
  [data]="data"
  [instance]="instance">
</zui-snack-error>

<zui-snack-success
  *zefSnackOutlet="let data = data; let instance = instance; type: 'success'"
  [data]="data"
  [instance]="instance">
</zui-snack-success>

<zui-snack-info
  *zefSnackOutlet="let data = data; let instance = instance; type: 'info'"
  [data]="data"
  [instance]="instance">
</zui-snack-info>
