import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { DriversBaseEffect } from './drivers-base.effect';

@NgModule({
  imports: [ EffectsModule.forFeature([ DriversBaseEffect ]) ]
})
export class DriversBaseModule {

}
