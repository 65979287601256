import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import {
  TableCellTemplateDirective,
  TableContainer,
  TableLinkTemplateDirective
} from './table.container';
import { NestedPathPipe } from './nested-path-pipe.pipe';

@NgModule({
  declarations: [
    TableContainer,
    TableLinkTemplateDirective,
    TableCellTemplateDirective,
    NestedPathPipe
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    ZefFormsModule,
    RouterModule,
    ZefScrollbarComponent
  ],
  exports: [
    TableContainer,
    TableLinkTemplateDirective,
    TableCellTemplateDirective
  ]
})
export class ZuiTableModule {

}
