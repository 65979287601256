/* eslint-disable @angular-eslint/no-input-rename */
import { Component, Input, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { zefSelectAuthData } from '@zerops/zef/auth';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { zefDialogOpen } from '@zerops/zef/dialog';
import { selectZefNgrxRouterParams, selectZefNgrxRouterQueryParams } from '@zerops/zef/ngrx-router';
import { TASK_DETAIL_DIALOG_KEY } from '@zerops/zemag/app';
import { EffortEntryEntity } from '@zerops/zemag/core/effort-entry-base';
import { StrapiUploadService } from '@zerops/zemag/core/strapi';
import { TasksEntity, transformTaskItem } from '@zerops/zemag/core/tasks-base';
import { UsersEntity } from '@zerops/zemag/core/users-base';
import { AssigneesPopService } from '@zerops/zemag/features/assignees-pop';
import { CategoryPopService } from '@zerops/zemag/features/category-pop';
import { EffortEntriesPopService, EFFORT_ENTRIES_POP_FEATURE_NAME } from '@zerops/zemag/features/effort-entries-pop';
import { EffortEntryAddEditPopService, EFFORT_ENTRY_ADD_EDIT_POP_FEATURE_NAME } from '@zerops/zemag/features/effort-entry-add-edit-pop';
import { EffortPopService } from '@zerops/zemag/features/effort-pop';
import { InternalPriorityPopService } from '@zerops/zemag/features/internal-priority-pop';
import { PriorityScorePopService } from '@zerops/zemag/features/priority-score-pop';
import { StatusPopService } from '@zerops/zemag/features/status-pop/status-pop.service';
import { TaskMorePopService } from '@zerops/zemag/features/task-more-pop';
import { CodeFieldComponent } from '@zerops/zui/code-field/code-field.component';
import { ObservableInput } from 'observable-input';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { MergeStrategy } from '@zerops/zef/entities';

@Component({
  selector: 'zg-task-detail',
  templateUrl: './task-detail.feature.html',
  styleUrls: [ './task-detail.feature.scss' ]
})
export class TaskDetailFeature extends ZefReactiveComponent {

  // # Event Streams
  onUpdate$ = new Subject<void>();
  onAddEffortEntry$ = new Subject<number>();
  onCloseDetail$ = new Subject<void>();

  // # Data
  // angular
  @ObservableInput()
  @Input('id')
  id$!: Observable<any>;

  @ObservableInput()
  @Input('edit')
  edit$!: Observable<boolean>;

  @ViewChild(CodeFieldComponent)
  codeFieldRef: CodeFieldComponent;

  // -- sync
  statusPopRef = this._statusPopService.getRef();
  effortPopRef = this._effortPopService.getRef();
  effortEntriesPopRef = this._effortEntriesPopService.getRef();
  categoryPopRef = this._categoryPopService.getRef();
  internalPriorityPopRef = this._internalPriorityPopService.getRef();
  priorityScorePopRef = this._priorityScorePopService.getRef();
  assigneesPopRef = this._assigneesPopService.getRef();
  taskMorePopRef = this._taskMorePopService.getRef();
  effortEntryAddEditPopRef = this._effortEntryAddEditPopService.getRef();
  editValues = {
    name: '',
    desc: '',
    discussion_link: ''
  };
  effortEntriesPopKey = EFFORT_ENTRIES_POP_FEATURE_NAME;
  effortEntryAddEditPopKey = EFFORT_ENTRY_ADD_EDIT_POP_FEATURE_NAME;
  detailDialogKey = TASK_DETAIL_DIALOG_KEY;

  // -- async
  routerParams$ = this._store.pipe(
    select(selectZefNgrxRouterParams)
  );
  queryParams$ = this._store.pipe(
    select(selectZefNgrxRouterQueryParams)
  );
  editMode$ = this.edit$.pipe(startWith(false));
  users$ = this._usersEntity.list$();
  task$ = this.id$.pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    switchMap((d) => this._tasksEntity.rawEntities$().pipe(
      map((e) => e && e[d] ? transformTaskItem(e[d]) : undefined),
      filter((d) => !!d),
      distinctUntilChanged((a, b) => a?.id !== b?.id)
    ))
  );
  effortEntries$ = this.id$.pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    switchMap((id) => this._effortEntryEntity
      .list$()
      .pipe(
        filter((d) => !!d),
        map((d) => d.filter((itm) => itm.attributes.task_id === parseInt(id, 10)))
      )
    )
  );
  effortByPeople$ = combineLatest([
    this.users$,
    this.effortEntries$
  ]).pipe(
    map(([ users, efforts ]) => {
      if (!users?.length || !efforts.length) {
        return [];
      }

      const effortByUser = efforts.reduce((obj, itm) => {
        if (obj[itm.attributes.user_id] === undefined) {
          obj[itm.attributes.user_id] = 0;
        }

        obj[itm.attributes.user_id] = obj[itm.attributes.user_id] + itm.attributes.hour_effort;

        return obj;
      }, {});

      return users
        .map((itm) => ({
          id: itm.id,
          username: itm.username,
          avatar: itm.avatar,
          effort: effortByUser[itm.id]
        }))
        .filter((itm) => !!itm.effort);

    })
  );

  // # State resolver
  state = this.$connect({
    task: this.task$,
    effortByPeople: this.effortByPeople$,
    users: this.users$,
    effortEntries: this.effortEntries$,
    editMode: this.editMode$
  });

  private _editor: any;

  // # Action Streams
  private _loadTaskByIdAction$ = this.id$.pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    map((id) => this._tasksEntity.searchAll(
      {
        populate: 'author.avatar.*,drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort',
        'filters[id][$eq]': id,
        'pagination[start]': 0,
        'pagination[limit]': 1
      },
      { zefListMergeStrategy: MergeStrategy.Noop }
    ))
  );
  private _updateAction$ = this.onUpdate$.pipe(
    withLatestFrom(this.task$),
    map(([ _, data ]) => this._tasksEntity.updateOne(
      data.id,
      {
        data: {
          ...this.editValues
        }
      },
      {
        isDialogEdit: true,
        strapiPopulate: {
          populate: 'drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort'
        }
      },
      {
        type: 'snack'
      }
    ))
  );
  private _addEffortEntryAction$ = this.onAddEffortEntry$.pipe(
    withLatestFrom(
      this.task$,
      this._store.pipe(
        select(zefSelectAuthData),
        map((d) => d.userId)
      )
    ),
    map(([ hours, task, userId ]) => this._effortEntryEntity.addOne({
      data: {
        created: new Date().toISOString(),
        hour_effort: hours,
        task_id: task.id,
        user_id: userId
      }
    }))
  );
  private _closeDetailAction$ = this.onCloseDetail$.pipe(
    withLatestFrom(this.id$),
    map(([ _, id ]) => zefDialogOpen({
      key: this.detailDialogKey,
      meta: { id, edit: false }
    }))
  );

  constructor(
    private _store: Store<any>,
    private _tasksEntity: TasksEntity,
    private _usersEntity: UsersEntity,
    private _effortEntryEntity: EffortEntryEntity,
    private _statusPopService: StatusPopService,
    private _effortPopService: EffortPopService,
    private _effortEntriesPopService: EffortEntriesPopService,
    private _categoryPopService: CategoryPopService,
    private _internalPriorityPopService: InternalPriorityPopService,
    private _priorityScorePopService: PriorityScorePopService,
    private _assigneesPopService: AssigneesPopService,
    private _taskMorePopService: TaskMorePopService,
    private _effortEntryAddEditPopService: EffortEntryAddEditPopService,
    private _strapiUploadService: StrapiUploadService
  ) {
    super();

    this.task$
      .pipe(
        filter((d) => !!d),
        tap((d) => {
          this.editValues = {
            name: d.attributes.name,
            desc: d.attributes.desc,
            discussion_link: d.attributes.discussion_link
          };
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe()

    // # Dispatcher
    this.$dispatchActions([
      this._updateAction$,
      this._addEffortEntryAction$,
      this._closeDetailAction$,
      this._loadTaskByIdAction$
    ]);

  }

  editorInit(editor: any) {
    if (!editor) { return; }

    this._editor = editor;
  }

  onPaste(e: any) {
    if (this._editor?.hasTextFocus()) {
      const items = e.clipboardData.items;

      const selection = this._editor.getSelection();

      for (const item of items) {
        const matches = item.type.match(/^image\/(png|jpg|jpeg|gif)$/i);
        if (matches) {
          const blob = item.getAsFile();
          this._strapiUploadService.upload(blob).subscribe((res: any) => {
            this._editor.executeEdits('', [
              {
                range: selection,
                /**
                range: new Range(
                  selection.endLineNumber,
                  selection.endColumn,
                  selection.endLineNumber,
                  selection.endColumn
                  ),
                 */
                text: `![](${res[0].url})`
              }
            ])
            const { endLineNumber, endColumn } = this._editor.getSelection();
            this._editor.setPosition({ lineNumber: endLineNumber, column: endColumn });
          })
        }
      }

    }
  }

}
