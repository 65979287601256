import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { ApiEntityKeys } from '@zerops/zemag/app';
import { generateSlugMap } from '@zerops/zemag/core/slug-state';
import { UsersEntity } from './users-base.entity';
import { select, Store } from '@ngrx/store';
import { zefSelectAuthState } from '@zerops/zef/auth';
import { ZefAuthState } from '@zerops/zef/auth/auth.model';

@Injectable()
export class UsersBaseEffect {

  isAuthorized$ = this._store.pipe(
    select(zefSelectAuthState),
    filter((s) => s === ZefAuthState.Authorized)
  );

  private _onInit$ = createEffect(() => this.isAuthorized$.pipe(
    map(() => this._entity.searchAll({
      sort: this._entity.defaultApiSort,
      populate: 'avatar.*'
    }))
  ));

  private _onListChange$ = createEffect(() => this._entity
    .all$()
    .pipe(
      filter((d) => !!(!!d && d.length)),
      distinctUntilChanged((a, b) => a?.length === b?.length),
      map((items) => generateSlugMap({ items, key: ApiEntityKeys.Users }))
    )
  );

  constructor(
    private _entity: UsersEntity,
    private _store: Store<any>
  ) { }

}
