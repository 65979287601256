import { NgModule } from '@angular/core';
import { TasksBaseModule } from './tasks-base';
import { TaskStatusBaseModule } from './task-status-base';
import { TaskEffortBaseModule } from './task-effort-base';
import { UsersBaseModule } from './users-base';
import { DriversBaseModule } from './drivers-base';
import { TaskInternalPriorityBaseModule } from './task-internal-priority-base';
import { TaskCategoryBaseModule } from './task-category-base';
import { EffortEntryBaseModule } from './effort-entry-base/effort-entry-base.module';

@NgModule({
  imports: [
    TasksBaseModule,
    TaskStatusBaseModule,
    UsersBaseModule,
    DriversBaseModule,
    TaskInternalPriorityBaseModule,
    TaskCategoryBaseModule,
    TaskEffortBaseModule,
    EffortEntryBaseModule
  ]
})
export class CoreModule {}
