import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { MergeStrategy } from '@zerops/zef/entities';
import { SatPopover } from '@zerops/zef/popover';
import { TasksEntity, Task } from '@zerops/zemag/core/tasks-base';
import { UsersEntity } from '@zerops/zemag/core/users-base';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './assignees-pop.constant';
import { AssigneesPopService } from './assignees-pop.service';

@Component({
  selector: 'zg-assignees-pop',
  templateUrl: './assignees-pop.feature.html',
  styleUrls: [ './assignees-pop.feature.scss' ]
})
export class AssigneesPopFeature extends ZefReactiveComponent implements AfterViewInit {

  value = [];
  doneMap = {};

  // # Event Streams
  onUpdate$ = new Subject<{ id: number; data: any; } | void>();

  // # Data
  // -- sync
  featureName = FEATURE_NAME;

  // -- angular
  @ViewChild(SatPopover, { static: true })
  popRef: SatPopover;

  // -- async
  users$ = this._usersEntity.list$().pipe(map((d) => d?.filter((itm) => !itm.archived)));
  popState$ = this._store.pipe(
    select(selectZefDialogState(this.featureName))
  );
  id$ = this.popState$.pipe(
    map((d) => d.meta as number)
  );
  task$ = this.id$.pipe(
    switchMap((id) => id
      ? this._tasksEntity
        .entityById$(id)
        .pipe(
          filter((d) => !!d),
          tap((d) => {
            this.doneMap = d.attributes?.assignees
              .filter((d) => d.done)
              .reduce((obj, itm) => {
                obj[itm.user.data.id] = itm.done;
                return obj;
              }, {}) || {};
            this.value = d.attributes?.assignees.map((d) => d.user.data.id) || [];
          })
        )
      : of({}) as Observable<Task>
    )
  );

  // # State resolver
  state = this.$connect({
    users: this.users$,
    popState: this.popState$,
    id: this.id$,
    task: this.task$
  });

  // # Action Streams
  private _updateAction$ = this.onUpdate$.pipe(
    withLatestFrom(this.task$),
    map(([ _, task ]) => {

      const assignees = task.attributes.assignees;
      const valueLen = this.value.length;
      const currentLen = assignees.length;

      let combinedAssignees = [];

      if (currentLen > 0) {

        const updatedAssignees = assignees
          .slice(0, valueLen)
          .map((itm, index) => {
            const user = this.value[index];
            return {
              id: itm.id,
              done: this.doneMap[user] || false,
              user
            };
          });

          combinedAssignees = updatedAssignees;

      }

      if (valueLen > currentLen) {

        const newValues = currentLen > 0
          ? this.value.slice(currentLen, valueLen)
          : this.value;

        combinedAssignees = [
          ...combinedAssignees,
          ...newValues.map((user) => {
            return {
              done: this.doneMap[user] || false,
              user
            };
          })
        ];

      }

      return this._tasksEntity.updateOne(
        task.id,
        {
          data: {
            assignees: combinedAssignees
          }
        },
        {
          zefEntityMergeStrategy: MergeStrategy.KeepNew,
          strapiPopulate: {
            populate: 'drivers.driver.*,assignees.user.*,assignees.user.avatar.*,task_category,internal_priority,status,task_effort'
          }
        },
        {
          type: 'snack'
        }
      );
    })
  );

  constructor(
    private _tasksEntity: TasksEntity,
    private _usersEntity: UsersEntity,
    private _assigneesPopService: AssigneesPopService,
    private _store: Store<any>
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([ this._updateAction$ ]);

  }

  ngAfterViewInit() {
    this._assigneesPopService.saveRef(this.popRef);
  }

  onValueChange(e: any) {
    this.value = e.value;
  }

  doneChange(id: number, e: any) {
    this.doneMap[id] = e.checked;
  }
}
