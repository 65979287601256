import { createReducer, on } from '@ngrx/store';
import { SlugStateState } from './slug-state.model';
import {
  generateSlugMap,
  SlugStateActionUnion
} from './slug-state.action';

const initialState = new SlugStateState();

const actionReducer = createReducer(
  initialState,
  on(
    generateSlugMap,
    (s, { items, key }): SlugStateState => ({
      ...s,
      slugMap: {
        ...s.slugMap,
        [key]: items.reduce((obj, item) => {
          obj[item.slug] = item.id;
          return obj;
        }, {})
      }
    })
  )
);

export function slugStateReducer(
  state: SlugStateState,
  action: SlugStateActionUnion
) {
  return actionReducer(state, action);
}
