import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { EntityStoreService } from '@zerops/zef/entities/entity-store.service';
import { Sort } from '@zerops/zef/sort';
import { StrapiDataService } from '@zerops/zemag/core/strapi';
import { ApiEntityKeys } from '@zerops/zemag/app';
import { TaskStatus } from './task-status-base.model';

@Injectable({ providedIn: 'root' })
export class TaskStatusEntity extends EntityService<TaskStatus> {

  defaultSort: Sort = {
    key: 'priority',
    direction: 'asc'
  };
  defaultLimit = 1000;
  defaultApiSort = 'priority:ASC'

  constructor(
    public store: Store<any>,
    public collectionManager: CollectionManagerService,
    private _httpClient: HttpClient,
    private _entityStoreService: EntityStoreService
  ) {
    super(ApiEntityKeys.TaskStatus, store, collectionManager);

    this._entityStoreService.registerService(
      ApiEntityKeys.TaskStatus,
      new StrapiDataService<Task>(ApiEntityKeys.TaskStatus, this._httpClient)
    );
  }
}
