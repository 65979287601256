import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { PopStateModule } from '@zerops/zui/pop-state';
import { AssigneesPopFeature } from './assignees-pop.feature';
import { AssigneesPopEffect } from './assignees-pop.effect';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ZefFormFieldModule, ZefFormLabelModule, ZefFormModule } from '@zerops/zef/forms/modules';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';

@NgModule({
  declarations: [ AssigneesPopFeature ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ AssigneesPopEffect ]),
    SatPopoverModule,
    MatCardModule,
    MatListModule,
    ActionListItemModule,
    PopStateModule,
    ZefFormFieldModule,
    ZefFormModule,
    ZefFormLabelModule,
    MatButtonModule,
    MatCheckboxModule,
    MatButtonToggleModule
  ],
  exports: [ AssigneesPopFeature ]
})
export class AssigneesPopModule {

}
