import { Component, Input } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { EffortEntryEntity } from '@zerops/zemag/core/effort-entry-base';
import { zefSelectAuthData } from '@zerops/zef/auth';
import { select, Store } from '@ngrx/store';
import { ObservableInput } from 'observable-input';
import { selectZefProgressMapByType } from '@zerops/zef/progress';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { EffortEntryAddEditPopService, EFFORT_ENTRY_ADD_EDIT_POP_FEATURE_NAME } from '../effort-entry-add-edit-pop';
import { SatPopover } from '@zerops/zef/popover';

@Component({
  selector: 'zg-effort-entries-table',
  templateUrl: './effort-entries-table.feature.html',
  styleUrls: [ './effort-entries-table.feature.scss' ]
})
export class EffortEntriesTableFeature extends ZefReactiveComponent {

  // # Event Streams
  onDelete$ = new Subject<number>();

  // # Data
  // -- angular
  @ObservableInput()
  /* eslint-disable-next-line @angular-eslint/no-input-rename */
  @Input('userId')
  userId$!: Observable<string>;

  @ObservableInput()
  /* eslint-disable-next-line @angular-eslint/no-input-rename */
  @Input('taskId')
  taskId$!: Observable<string>;

  // -- sync
  columns = [
    {
      name: 'Task',
      key: 'attributes.task_id'
    },
    {
      name: 'Created',
      key: 'attributes.created',
      type: 'date'
    },
    {
      name: 'Effort',
      key: 'attributes.hour_effort'
    },
    {
      name: 'actions',
      key: 'actions'
    }
  ];
  effortEntryAddEditPopRef: SatPopover;
  effortEntryAddEditPopKey = EFFORT_ENTRY_ADD_EDIT_POP_FEATURE_NAME;

  // -- async
  effortEntries$ = this.userId$.pipe(
    switchMap((id) => this._effortEntryEntity.list$().pipe(
      map((d) => d?.filter((e) => e.attributes.user_id === parseInt(id)))
    ))
  );
  taskEffortEntries$ = combineLatest(
    this.effortEntries$,
    this.taskId$
  ).pipe(
    filter(([ _, taskId ]) => !!taskId),
    map(([ d, taskId ]) => d?.filter((t) => t.attributes.task_id === parseInt(taskId)))
  );
  authorizedUserId$ = this._store.pipe(
    select(zefSelectAuthData),
    map((d) => d?.userId),
    distinctUntilChanged()
  );
  deleteLoadingsMap$ = this._store.pipe(
    select(selectZefProgressMapByType(this._effortEntryEntity.deleteOne.type))
  );

  // # State resolver
  state = this.$connect({
    effortEntries: this.effortEntries$,
    taskEffortEntries: this.taskEffortEntries$,
    authorizedUserId: this.authorizedUserId$,
    userId: this.userId$,
    taskId: this.taskId$,
    deleteLoadingsMap: this.deleteLoadingsMap$
  });

  // # Action Streams
  private _deleteAction$ = this.onDelete$.pipe(
    map((id) => this._effortEntryEntity.deleteOne(
      id,
      undefined,
      {
        type: 'snack'
      }
    ))
  );

  constructor(
    private _store: Store<any>,
    private _effortEntryEntity: EffortEntryEntity,
    private _effortEntryAddEditPopService: EffortEntryAddEditPopService
  ) {
    super();

    setTimeout(() => {
      this.effortEntryAddEditPopRef = this._effortEntryAddEditPopService.getRef();
    }, 0);

    // # Dispatcher
    this.$dispatchActions([
      this._deleteAction$
    ]);
  }

}
