<div class="contenedor">
  <div class="cube">
    <div class="card">
      <img [src]="user?.avatar?.url" class="__image" />
    </div>
    <div class="card">
      <img [src]="user?.avatar?.url" class="__image" />
    </div>
    <div class="card">
      <zui-logo class="__logo"></zui-logo>
    </div>
    <div class="card">
      <zui-logo class="__logo"></zui-logo>
    </div>
    <div class="card">
      <img [src]="user?.avatar?.url" class="__image" />
    </div>
    <div class="card">
      <zui-logo class="__logo"></zui-logo>
    </div>
  </div>
</div>
