<sat-popover
  [autoFocus]="false"
  [restoreFocus]="false"
  [verticalAlign]="'below'"
  [horizontalAlign]="'center'"
  hasBackdrop
  [zuiPopState]="featureName"
  (closed)="value = []; doneMap = {};"
  (backdropClicked)="popRef.close()">
  <mat-card class="__card  c-soft-elevation">

    <zef-form-field
      last
      class="__form-field"
      [appearance]="'button-toggle'">
      <mat-button-toggle-group multiple (change)="onValueChange($event)" [value]="value">

        <div
          class="__toggle-item"
          [class.is-checked]="value?.includes(item.id)"
          *ngFor="let item of state.users; trackBy: trackByIndex;">

          <mat-button-toggle
            class="__user-toggle"
            [value]="item.id">

            <div class="__user-avatar">
              <img
                class="__user-avatar_image"
                [src]="item.avatar?.url"
                [alt]="item.username"
              />
            </div>

            {{ item?.username }}

          </mat-button-toggle>

          <mat-checkbox
            color="primary"
            [disabled]="!value?.includes(item.id)"
            (change)="doneChange(item.id, $event)"
            [checked]="value?.includes(item.id) && doneMap[item.id]"
            class="__toggle-checkbox">
          </mat-checkbox>

        </div>

      </mat-button-toggle-group>

    </zef-form-field>

    <div class="__button-wrap">
      <button
        class="c-button--full"
        type="button"
        (click)="onUpdate$.next(); popRef.close()"
        mat-flat-button
        color="accent">
        Apply
      </button>
    </div>

  </mat-card>
</sat-popover>
