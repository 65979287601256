<!-- Effort entries pop -->
<sat-popover
  [autoFocus]="false"
  [restoreFocus]="false"
  [verticalAlign]="'below'"
  [horizontalAlign]="'end'"
  hasBackdrop
  [zuiPopState]="featureName"
  (backdropClicked)="popRef.close()">
  <mat-card class="__card  c-soft-elevation">
    <zg-effort-entries-table
      [userId]="state.userId"
      [taskId]="state.taskId">
    </zg-effort-entries-table>
  </mat-card>
</sat-popover>
