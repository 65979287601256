import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZuiTableModule } from '@zerops/zui/table';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { ProgressSpinnerModule } from '@zerops/zui/progress-spinner';
import { EffortEntriesTableFeature } from './effort-entries-table.feature';

@NgModule({
  declarations: [ EffortEntriesTableFeature ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    ZuiTableModule,
    DynamicPopAnchorModule,
    ProgressSpinnerModule,
    ZefDialogModule
  ],
  exports: [ EffortEntriesTableFeature ]
})
export class EffortEntriesTableModule {

}
